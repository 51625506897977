import { injectSpecToBuilder } from "../../utils/formBuilder.utils";
import { DealIntegrationsFieldSpecs } from "../spec-files/deal/deal.fields";
import { PropertyIntegrationsFieldSpecs } from "../spec-files/property/property.fields";
import { DealIntegrationsSections } from "../types/dealIntegrations.types";
import { PropertyIntegrationsSections } from "../types/propertyIntegrations.types";
import { floodSectionBuilders } from "./flood/flood.sections";
import { siteXSectionBuilders } from "./property-report/siteX.sections";
import { rentRangeSectionBuilders } from "./rental-data/rentRange.section";
import { wireInsuranceSectionBuilders } from "./wire-insurance/fundingShield.section";

export const getPropertyIntegrationsSections = <
  T extends PropertyIntegrationsFieldSpecs
>(
  spec: T
) => {
  const serviceLinkFloodSections = injectSpecToBuilder<
    PropertyIntegrationsSections["FloodCertification"]["ServiceLink"]
  >({
    builders: floodSectionBuilders,
    spec
  });

  const siteXPropertyReportSections = injectSpecToBuilder<
    PropertyIntegrationsSections["PropertyReport"]["SiteX"]
  >({
    builders: siteXSectionBuilders,
    spec
  });

  const rentRangeRentalDataSections = injectSpecToBuilder<
    PropertyIntegrationsSections["RentalData"]["RentRange"]
  >({
    builders: rentRangeSectionBuilders,
    spec
  });

  return {
    serviceLinkFloodSections,
    siteXPropertyReportSections,
    rentRangeRentalDataSections
  };
};

export const getDealIntegrationsSections = <
  T extends DealIntegrationsFieldSpecs
>(
  spec: T
) => {
  const FundingShieldWireInsuranceCertificateSections =
    injectSpecToBuilder<
      DealIntegrationsSections["WireInsuranceCertificate"]["FundingShield"]
    >({
      builders: wireInsuranceSectionBuilders,
      spec
    }) || {};

  return { FundingShieldWireInsuranceCertificateSections };
};
