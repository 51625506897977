import { DotNestedKeys } from "@elphi/types/utils/flatten";
import { isArray, mergeWith, omit } from "lodash";
import { InputBuilderFieldSpecs } from "../../../components/form-builder/field-specs/fields.types";

type MergeReturnType<T extends object[]> = T extends [
  infer U extends object,
  ...infer R extends object[]
]
  ? U & MergeReturnType<R>
  : {};

export const omitFromSpec = <T extends object>(r: {
  obj: T;
  paths: DotNestedKeys<T>[];
}) => {
  return omit(r.obj, r.paths);
};

export const mergeSpecs = <T extends object[]>(
  ...objects: T
): MergeReturnType<T> => {
  return mergeWith({}, ...objects, (_, srcValue) => {
    if (isArray(srcValue)) {
      return srcValue;
    }
  });
};

export const modifyFromSpec = <T extends InputBuilderFieldSpecs<any>>(r: {
  fieldSpecs: T;
  changes: Partial<InputBuilderFieldSpecs<any>>;
}) => {
  const { fieldSpecs, changes } = r;
  return mergeSpecs({}, fieldSpecs, changes);
};
