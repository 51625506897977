import { Deal, Property } from "@elphi/types";
import { LimaProperty } from "@elphi/types/entities/lima/property";
import {
  hideBridgePlus,
  hideFixNFlip,
  hideFixNFlipNoBridge,
  hideRental30,
  hideRental30OrEmpty,
  isEmptyLoanProgramType,
  isFixNFlip,
  isNewConstruction,
  isRental30Premier
} from "../../../../components/application/sections/sections.utils";
import {
  OnChangeInput,
  Section
} from "../../../../components/form-builder/FormBuilder";
import {
  PSAExpirationDate,
  allocatedLoanAmount,
  annualHomeownersAssociationFeeAmount,
  annualTaxAmount,
  appraisalCompletedDate,
  appraisalEffectiveDate,
  appraisalExpirationDate,
  appraisalForm1007RequiredIndicator,
  appraisalManagementCompanyName,
  appraisalManagementContactPointEmailValue,
  appraisalManagementContactPointTelephoneValue,
  appraisalManagementRepresentativeFullName,
  appraisalOrderedDate,
  appraisalType,
  appraisalValueDerivationType,
  assignmentFeeAmount,
  borrowerRenovationCostsAfterPurchaseAmount,
  conditionRatingType,
  condoWarrantableIndicator,
  constructionBudgetAssessment,
  constructionCostAmount,
  constructionCostComments,
  contractClosingDate,
  financedUnitCount,
  inspectionDocumentsReceivedDate,
  inspectionInitialDelayReasonStatusType,
  inspectionInitialRequestDate,
  inspectionOrderProcessedDate,
  inspectionOrderReceivedDate,
  inspectionOrderType,
  inspectionReportDueDate,
  inspectorFullName,
  leaseStatusType,
  leaseStrategy,
  legalDescription,
  ltcRatePercent,
  originalPurchaseDate,
  originalPurchasePriceAmount,
  outstandingLoanPayoffAmount,
  parcelNumber,
  payoffExpirationDate,
  pricingEngineLTARVRatePercent,
  propertyAccessInformation,
  propertyAddressLineText,
  propertyAddressUnitIdentifier,
  propertyAreaValue,
  propertyBlockIdentifier,
  propertyCityName,
  propertyCountyName,
  propertyLoanPurpose,
  propertyLotIdentifier,
  propertyPostalCode,
  propertyRightsOwnershipType,
  propertyStateCode,
  propertyStateName,
  propertyStructureBuiltYear,
  propertySubdivisionIdentifier,
  propertyType,
  propertyValuationAmount,
  propertyZoningComplianceRatingType,
  propertyZoningDescription,
  psaAssignmentContractIndicator,
  psaExtensionRequestedIndicator,
  purchasePriceAmount,
  rentalLTVPercent,
  specificZoningClassification,
  subjectToPropertyValuationAmount,
  totalBathroomCount,
  totalBedroomCount,
  totalCostAmount
} from "../../base/inputs/property.inputs";
import {
  addRuleToInput,
  addRulesToInputs,
  buildSection
} from "../../utils/formBuilder.utils";
import {
  PSABuyerBorrowerMismatch,
  annualCapitalExpenditure,
  appraisalCoordinator,
  bridgeLTVPercent,
  cemaRefinanceIndicator,
  condoCertificateComments,
  condoCertificateFeesCollectedIndicator,
  contractExecutionDate,
  defaultInterestPaymentsOrFeesNotedIndicator,
  floodInsuranceAddressLineText,
  floodInsuranceAddressUnitIdentifier,
  floodInsuranceCityName,
  floodInsuranceContactPointEmailValue,
  floodInsuranceContactPointTelephoneValue,
  floodInsuranceDeductibleAmount,
  floodInsuranceDwellingCoverageAmount,
  floodInsuranceFullName,
  floodInsuranceNextPaymentDueDate,
  floodInsurancePolicyExpirationDate,
  floodInsurancePolicyNumber,
  floodInsurancePostalCode,
  floodInsurancePremiumAmount,
  floodInsurancePremiumOutstandingAmount,
  floodInsurancePremiumPaymentType,
  floodInsuranceRepresentativeFullName,
  floodInsuranceStateCode,
  inspectionRequestOrderedDate,
  internalL1CRefinance,
  internalValuationEligibilityIndicator,
  mortgageeContactFullName,
  mortgageeContactPointEmailValue,
  mortgageeContactPointTelephone,
  mortgageeFullName,
  propertyDetailComments,
  propertyInsuranceAddressLineText,
  propertyInsuranceAddressUnitIdentifier,
  propertyInsuranceAgentFullName,
  propertyInsuranceBuilderRiskInsurance,
  propertyInsuranceCityName,
  propertyInsuranceContactPointEmail,
  propertyInsuranceContactPointTelephone,
  propertyInsuranceDeductibleAmount,
  propertyInsuranceDwellingCoverageAmount,
  propertyInsuranceFullName,
  propertyInsuranceLiabilityCoverageAmount,
  propertyInsuranceNextPaymentDueDate,
  propertyInsurancePolicyExpirationDate,
  propertyInsurancePolicyIdentifier,
  propertyInsurancePostalCode,
  propertyInsurancePremiumAmount,
  propertyInsurancePremiumOutstandingAmount,
  propertyInsurancePremiumPaymentType,
  propertyInsuranceRentalLossCoverageAmount,
  propertyInsuranceReplacementCoverageIndicator,
  propertyInsuranceStateCode,
  psaAddendumIndicator,
  psaExtensionFormIndicator,
  psaSellerOwnerRecordMismatchIndicator,
  qualityRatingType
} from "../inputs/property.inputs";
import { limaSpecs } from "../spec-files/limaFieldsSpecs";
import { LimaSections } from "../types";

const propertyDescriptionSection = (r: {
  state: Partial<LimaProperty>;
  onChange?: (v: OnChangeInput) => void;
}): Section =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Property Description",
    inputs: [
      propertyAddressLineText,
      propertyAddressUnitIdentifier,
      propertyCityName,
      propertyCountyName,
      propertyStateName,
      propertyStateCode,
      propertyPostalCode,
      propertyType,
      financedUnitCount,
      parcelNumber,
      legalDescription,
      propertyLotIdentifier,
      propertyBlockIdentifier,
      propertySubdivisionIdentifier,
      propertyRightsOwnershipType,
      condoWarrantableIndicator,
      condoCertificateFeesCollectedIndicator,
      condoCertificateComments
    ],
    fieldSpecs: limaSpecs.property.entitySpecs
  });

const propertyDetailSection = (r: {
  state: Partial<LimaProperty>;
  dealState?: Deal;
  onChange?: (v: OnChangeInput) => void;
}): Section => {
  const dealState = r.dealState || {};
  return buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Property Details",
    inputs: [
      propertyLoanPurpose,
      purchasePriceAmount,
      outstandingLoanPayoffAmount,
      defaultInterestPaymentsOrFeesNotedIndicator,
      allocatedLoanAmount,
      addRuleToInput({
        input: bridgeLTVPercent,
        state: dealState,
        rules: [
          {
            field: "isHidden",
            predicate: () => hideBridgePlus(dealState)
          }
        ]
      }),
      addRuleToInput({
        input: rentalLTVPercent,
        state: dealState,
        rules: [
          {
            field: "isHidden",
            predicate: () => hideRental30(dealState)
          }
        ]
      }),
      addRuleToInput({
        input: pricingEngineLTARVRatePercent,
        state: dealState,
        rules: [
          {
            field: "isHidden",
            predicate: () => hideFixNFlipNoBridge(dealState)
          }
        ]
      }),
      ...addRulesToInputs({
        inputs: [constructionCostAmount, constructionCostComments],
        state: dealState,
        rules: [
          {
            field: "isHidden",
            predicate: () => hideFixNFlip(dealState)
          }
        ]
      }),
      addRuleToInput({
        input: leaseStatusType,
        state: dealState,
        rules: [
          {
            field: "isHidden",
            predicate: () => hideRental30(dealState)
          }
        ]
      }),
      originalPurchaseDate,
      originalPurchasePriceAmount,
      borrowerRenovationCostsAfterPurchaseAmount,
      ltcRatePercent,
      ...addRulesToInputs({
        inputs: [annualTaxAmount, annualHomeownersAssociationFeeAmount],
        state: dealState,
        rules: [
          {
            field: "isHidden",
            predicate: () => hideRental30(dealState)
          }
        ]
      }),
      addRuleToInput({
        input: annualCapitalExpenditure,
        state: dealState,
        rules: [
          {
            field: "isHidden",
            predicate: () =>
              !isRental30Premier(dealState) &&
              !isEmptyLoanProgramType(dealState)
          }
        ]
      }),
      totalCostAmount,
      propertyDetailComments,
      internalL1CRefinance,
      addRuleToInput({
        input: leaseStrategy,
        state: dealState,
        rules: [
          {
            field: "isHidden",
            predicate: () => hideRental30(dealState)
          }
        ]
      }),
      addRuleToInput({
        input: constructionBudgetAssessment,
        state: dealState,
        rules: [
          {
            field: "isHidden",
            predicate: () =>
              !isNewConstruction(dealState) &&
              !isFixNFlip(dealState) &&
              !isEmptyLoanProgramType(dealState)
          }
        ]
      })
    ],
    fieldSpecs: limaSpecs.property.entitySpecs
  });
};

const purchaseAndSaleAgreementSection = (r: {
  state: Partial<LimaProperty>;
  onChange?: (v: OnChangeInput) => void;
}): Section =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Purchase and Sale Agreement",
    inputs: [
      contractExecutionDate,
      contractClosingDate,
      PSAExpirationDate,
      psaExtensionRequestedIndicator,
      psaExtensionFormIndicator,
      psaAssignmentContractIndicator,
      assignmentFeeAmount,
      psaAddendumIndicator,
      psaSellerOwnerRecordMismatchIndicator,
      PSABuyerBorrowerMismatch
    ],
    fieldSpecs: limaSpecs.property.entitySpecs
  });

const payoffSection = (r: {
  state: Partial<LimaProperty>;
  onChange?: (v: OnChangeInput) => void;
}): Section =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Payoff",
    inputs: [
      payoffExpirationDate,
      mortgageeFullName,
      mortgageeContactFullName,
      mortgageeContactPointTelephone,
      mortgageeContactPointEmailValue,
      cemaRefinanceIndicator
    ],
    fieldSpecs: limaSpecs.property.entitySpecs
  });

const valuationSection = (r: {
  state: Partial<LimaProperty>;
  dealState?: Deal;
  onChange?: (v: OnChangeInput) => void;
}): Section => {
  const dealState = r.dealState || {};
  return buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Valuation",
    inputs: [
      appraisalValueDerivationType,
      propertyValuationAmount,
      addRuleToInput({
        input: subjectToPropertyValuationAmount,
        state: dealState,
        rules: [
          {
            field: "isHidden",
            predicate: () => !hideRental30OrEmpty(dealState)
          }
        ]
      }),
      appraisalOrderedDate,
      appraisalCompletedDate,
      appraisalEffectiveDate,
      appraisalExpirationDate,
      appraisalType,
      qualityRatingType,
      conditionRatingType,
      propertyAreaValue,
      totalBedroomCount,
      totalBathroomCount,
      propertyStructureBuiltYear,
      propertyCountyName,
      specificZoningClassification,
      propertyZoningDescription,
      propertyZoningComplianceRatingType,
      appraisalCoordinator,
      addRuleToInput({
        input: internalValuationEligibilityIndicator,
        state: dealState,
        rules: [
          {
            field: "isHidden",
            predicate: () => hideFixNFlip(dealState)
          }
        ]
      }),
      addRuleToInput({
        input: appraisalForm1007RequiredIndicator,
        state: dealState,
        rules: [
          {
            field: "isHidden",
            predicate: () => hideRental30(dealState)
          }
        ]
      }),
      appraisalManagementRepresentativeFullName,
      appraisalManagementContactPointTelephoneValue,
      appraisalManagementContactPointEmailValue,
      appraisalManagementCompanyName,
      propertyAccessInformation
    ],
    fieldSpecs: limaSpecs.property.entitySpecs
  });
};

const propertyInsuranceSection = (r: {
  state: Partial<Property>;
  dealState?: Deal;
  onChange?: (v: OnChangeInput) => void;
}): Section => {
  const dealState = r.dealState || {};
  return buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Property Insurance",
    inputs: [
      propertyInsuranceDwellingCoverageAmount,
      propertyInsuranceLiabilityCoverageAmount,
      propertyInsuranceReplacementCoverageIndicator,
      addRuleToInput({
        input: propertyInsuranceRentalLossCoverageAmount,
        state: dealState,
        rules: [
          {
            field: "isHidden",
            predicate: () => hideRental30(dealState)
          }
        ]
      }),
      propertyInsurancePremiumOutstandingAmount,
      propertyInsurancePremiumAmount,
      propertyInsurancePremiumPaymentType,
      propertyInsurancePolicyIdentifier,
      propertyInsurancePolicyExpirationDate,
      propertyInsuranceNextPaymentDueDate,
      propertyInsuranceDeductibleAmount,
      propertyInsuranceAgentFullName,
      propertyInsuranceFullName,
      propertyInsuranceContactPointTelephone,
      propertyInsuranceContactPointEmail,
      propertyInsuranceAddressLineText,
      propertyInsuranceAddressUnitIdentifier,
      propertyInsuranceCityName,
      propertyInsuranceStateCode,
      propertyInsurancePostalCode,
      propertyInsuranceBuilderRiskInsurance
    ],
    fieldSpecs: limaSpecs.property.entitySpecs
  });
};

const floodInsuranceSection = (r: {
  state: Partial<LimaProperty>;
  onChange: (v: OnChangeInput) => void;
}): Section =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Flood Insurance",
    inputs: [
      floodInsuranceDwellingCoverageAmount,
      floodInsurancePremiumAmount,
      floodInsurancePremiumPaymentType,
      floodInsurancePremiumOutstandingAmount,
      floodInsurancePolicyNumber,
      floodInsurancePolicyExpirationDate,
      floodInsuranceNextPaymentDueDate,
      floodInsuranceDeductibleAmount,
      floodInsuranceFullName,
      floodInsuranceRepresentativeFullName,
      floodInsuranceContactPointTelephoneValue,
      floodInsuranceContactPointEmailValue,
      floodInsuranceAddressLineText,
      floodInsuranceAddressUnitIdentifier,
      floodInsuranceCityName,
      floodInsuranceStateCode,
      floodInsurancePostalCode
    ],
    fieldSpecs: limaSpecs.property.entitySpecs
  });

const generalSection = (r: {
  state: Partial<LimaProperty>;
  onChange: (v: OnChangeInput) => void;
}): Section =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "General",
    inputs: [
      inspectionInitialRequestDate,
      inspectionRequestOrderedDate,
      inspectionReportDueDate,
      inspectionOrderType,
      inspectorFullName,
      inspectionOrderReceivedDate,
      inspectionOrderProcessedDate,
      inspectionDocumentsReceivedDate,
      inspectionInitialDelayReasonStatusType
    ],
    fieldSpecs: limaSpecs.property.entitySpecs
  });

const createPropertySection = (r: {
  state: Partial<LimaProperty>;
  options?: { hideAttachedComponent: boolean };
}): Section =>
  buildSection({
    state: r.state,
    header: "Create New",
    inputs: [
      propertyAddressLineText,
      propertyAddressUnitIdentifier,
      propertyCityName,
      propertyStateName,
      propertyStateCode,
      propertyPostalCode,
      propertyLotIdentifier,
      propertyBlockIdentifier,
      propertySubdivisionIdentifier
    ],
    hideAttachedComponent: r.options?.hideAttachedComponent,
    fieldSpecs: limaSpecs.property.entitySpecs
  });

export const propertySectionBuilders: LimaSections["property"] = {
  propertyDescriptionSection,
  propertyDetailSection,
  purchaseAndSaleAgreementSection,
  payoffSection,
  valuationSection,
  propertyInsuranceSection,
  floodInsuranceSection,
  generalSection,
  createPropertySection
};
