import { FieldType } from "@elphi/types";
import { LimaBasePartyFields } from "@elphi/types/entities/lima/party";
import { EntityFormFieldSpecs } from "../../../../../components/form-builder/field-specs/fields.types";
import {
  BaseSchemaCommonPartyFieldSpecs,
  baseSchemaCommonPartyFieldSpecs
} from "../../../base/spec-files/party/party.fields";
import { mergeSpecs } from "../../../utils/formsSpec.utils";

type LimaCommonPartyFieldSpecs = EntityFormFieldSpecs<LimaBasePartyFields>;

export type FullLimaCommonPartyFieldSpecs =
  Partial<BaseSchemaCommonPartyFieldSpecs> & LimaCommonPartyFieldSpecs;

export const limaCommonPartyFieldSpecs: LimaCommonPartyFieldSpecs = {
  CRMId: {
    fieldKey: ["CRMId"],
    fieldType: FieldType.String,
    label: "CRM ID"
  }
};

export const limaCommonPartySpecs: FullLimaCommonPartyFieldSpecs = mergeSpecs(
  baseSchemaCommonPartyFieldSpecs,
  limaCommonPartyFieldSpecs
);
