export const baseChannelType = {
  Retail: "Retail",
  Broker: "Broker",
  Correspondent: "Correspondent",
  Wholesale: "Wholesale"
} as const;

export const baseLoanProductType = {
  N51ARM: "5/1 ARM",
  N101ARM: "10/1 ARM",
  N5IO25ARM: "5 IO - 25ARM",
  Fixed: "Fixed",
  N71ARM: "7/1 ARM"
} as const;

export const baseLoanTermPeriodMonthCount = {
  N12: "12",
  N18: "18",
  N24: "24",
  N360: "360",
  N480: "480"
} as const;

export const basePaymentDueDay = {
  N10: "10",
  N1: "1"
} as const;

export const baseLoanPayment = {
  PI: "Principal & Interest",
  IO: "Interest Only",
  Hybrid: "Hybrid"
} as const;
