import { DealPartyRelationType, Party, PartyType } from "@elphi/types";
import { buildPartyName } from "@elphi/utils";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { useMemo } from "react";
import { EMPTY } from "../../../constants/common";
import { useDealPartyRelationHooks } from "../../../hooks/dealpartyrelation.hooks";
import { usePartyHooks } from "../../../hooks/party.hooks";
import { FuzzySearchSelect } from "../../search/fuzzy/FuzzySearchSelect";
import { SelectSearchProps } from "../../search/search.types";

const buildOption = (p: Party) => {
  const label = buildPartyName(p);
  const value = p.id;
  return {
    ...p,
    label,
    value
  };
};

export const PosPartySearch = (
  props: SelectSearchProps & { dealId: string; selectedPartyIds: Set<string> }
) => {
  const { partyState, searchParty, searchResponse } = usePartyHooks();
  const { dealPartyRelationState } = useDealPartyRelationHooks();
  const partyIds = useMemo(
    () =>
      new Set(
        Object.values(dealPartyRelationState.entities)
          .filter(
            (e) =>
              removeEmpty(e) &&
              e?.dealId === props.dealId &&
              e?.type === DealPartyRelationType.DealIndividual
          )
          .filter(removeEmpty)
          .map((e) => e.partyId)
      ),
    [dealPartyRelationState.entities, props.dealId]
  );

  return (
    <FuzzySearchSelect
      {...props}
      label={"Search Individual Party"}
      searchApi={searchParty}
      searchResponse={searchResponse}
      state={partyState}
      configuration={{
        includeScore: true,
        threshold: 0.4,
        keys: ["FirstName", "LastName", "MiddleName"],
        shouldSort: true
      }}
      buildOption={buildOption}
      filterOption={(e) =>
        e.data?.PartyType === PartyType.Individual &&
        partyIds.has(e.data?.id || EMPTY) &&
        !props.selectedPartyIds.has(e.data?.id || EMPTY)
      }
      wrapperStyle={{ p: "0px" }}
    />
  );
};
