import { ElphiEntityType } from "../entities";

export enum FirebaseCollections {
  DocumentPackageOrder = "document_package_order",
  DocumentConfiguration = "document_configuration",
  DocumentRuleTemplate = "document_rule_template",
  TaskConfiguration = "task_configuration",
  TaskRuleTemplate = "task_rule_template",
  TaskTemplate = "task_template",
  Auth = "auth",
  Property = "property",
  Party = "party",
  DealPartyRelation = "loan_party_relationship",
  DealProperty = "loan_property_relationship",
  PartyRelation = "party_relationship",
  PartyAsset = "party_asset_relationship",
  Deal = "loan_data",
  DealQuote = "deal_quote",
  Asset = "asset",
  Statement = "statement",
  Task = "taskV2",
  Team = "team",
  Group = "group",
  Organization = "organization",
  DealUser = "deal_user_relationship",
  PartyUser = "party_user_relationship",
  LOSUser = "los_user",
  Snapshot = "snapshot",
  CreditScore = "credit_score",
  Role = "role",
  Permission = "permission",
  RoleGroup = "role_group",
  AuditEvent = "audit_event",
  DealCalendarView = "deal_calendar_view",
  RolodexServiceDomainConfiguration = "rolodex_service_domain_configuration",
  RolodexServiceProvider = "rolodex_service_provider",
  RolodexBranchRepresentativeRelation = "rolodex_branch_representative_relation",
  RolodexServiceProviderView = "rolodex_service_provider_view",
  PartyGroup = "party_group",
  PartyValidationConfiguration = "party_validation_configuration",
  InsurancePolicy = "insurance_policy",
  FieldGroup = "field_group",
  AuditEventGroup = "audit_event_group",
  NotificationConfiguration = "notification_configuration",
  InsurancePolicyView = "insurance_policy_view",
  NotificationBatchQueue = "notification_batch_queue",
  NotificationUser = "notification_user",
  Notifications = "notifications",
  DiscussionEntity = "discussion_entity",
  DiscussionComments = "discussion_comments",
  DiscussionUser = "discussion_user",
  DiscussionNotifierUserEntity = "discussion_notifier_user_entity",
  TaskInstruction = "task_instruction",
  LOSSettings = "los_settings",
  POSUser = "pos_user",
  POSDealUserParty = "pos_deal_user_party",
  POSDealUserPartyView = "pos_deal_user_party_view",
  EmailTemplate = "email_template"
}

export const tenantCollections: FirebaseCollections[] = [
  /**
   * making sure we dont have any duplications,
   * currently lodash wont be installed in shared/types so "Set" should be used to remove duplications
   */
  ...new Set([
    FirebaseCollections.Deal,
    FirebaseCollections.Property,
    FirebaseCollections.DealProperty,
    FirebaseCollections.DealPartyRelation,
    FirebaseCollections.DealQuote,
    FirebaseCollections.DealUser,
    FirebaseCollections.Party,
    FirebaseCollections.PartyAsset,
    FirebaseCollections.PartyRelation,
    FirebaseCollections.PartyUser,
    FirebaseCollections.CreditScore,
    FirebaseCollections.Asset,
    FirebaseCollections.LOSUser,
    FirebaseCollections.DocumentConfiguration,
    FirebaseCollections.DocumentPackageOrder,
    FirebaseCollections.DocumentRuleTemplate,
    FirebaseCollections.Task,
    FirebaseCollections.TaskConfiguration,
    FirebaseCollections.TaskRuleTemplate,
    FirebaseCollections.TaskTemplate,
    FirebaseCollections.Permission,
    FirebaseCollections.Role,
    FirebaseCollections.Snapshot,
    FirebaseCollections.Statement
  ])
];
export const orgConfigurationCollections = [
  FirebaseCollections.TaskConfiguration,
  FirebaseCollections.TaskRuleTemplate,
  FirebaseCollections.TaskTemplate,
  FirebaseCollections.Permission,
  FirebaseCollections.Role,
  FirebaseCollections.DocumentRuleTemplate,
  FirebaseCollections.DocumentConfiguration
];
export const platformCollections: FirebaseCollections[] = [
  FirebaseCollections.Organization
];

export const entityToCollection: {
  [P in ElphiEntityType]: FirebaseCollections;
} = {
  deal: FirebaseCollections.Deal,
  party: FirebaseCollections.Party,
  property: FirebaseCollections.Property,
  task: FirebaseCollections.Task,
  "deal-party": FirebaseCollections.DealPartyRelation,
  "party-relation": FirebaseCollections.PartyRelation,
  "deal-property": FirebaseCollections.DealProperty,
  asset: FirebaseCollections.Asset,
  statement: FirebaseCollections.Statement,
  "credit-score": FirebaseCollections.CreditScore,
  "service-provider": FirebaseCollections.RolodexServiceProvider,
  "insurance-policy": FirebaseCollections.InsurancePolicy
};
