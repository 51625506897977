import { BaseEntityWithCorrelationId } from "../../db";

export enum PosUserStatus {
  Pending = "pending",
  Active = "active",
  Inactive = "inactive",
  Blocked = "blocked"
}

export type PosUser = BaseEntityWithCorrelationId<{
  firstName: string;
  lastName: string;
  email: string;
  status: PosUserStatus;
}>;
