import {
  AggregationFocusType,
  ARMRepriceLookbackType,
  ARMRepriceRoundingMethodType,
  ARMRepriceRoundingRatePercentType,
  CapitalStructureType,
  CollateralPackageStatusType,
  DealExceptionApprovedBy,
  DealMilestoneType,
  DealOperationsDepartmentType,
  ExceptionType,
  FieldType,
  FixNFlipNBridgePlusTierType,
  IndexSourceType,
  InterestAccrualMethodType,
  LenderEntityType,
  LoanProgramType,
  LoanPurposeType,
  PricingEngineExceptionStatusType,
  PropertyInsurancePremiumPaymentType,
  PropertyModelType,
  PropertyRightsOwnershipType,
  RealEstateProgramType,
  ShippingCompanyType,
  StateName,
  TitleVestingType,
  UnderwriterApprovalStatusType,
  UserRoleType,
  WarehouseBankStatusType
} from "@elphi/types";
import {
  baseChannelType,
  baseFloodInsurancePremiumPayment,
  baseLoanPayment,
  baseLoanProductType,
  baseLoanTermPeriodMonthCount,
  basePaymentDueDay
} from "@elphi/types/entities/base";
import { BaseSchemaDeal } from "@elphi/types/entities/base/deal";
import { addressFieldSpecs } from "../../../../../components/form-builder/field-specs/address/address.fields";
import { baseEntityFormFieldSpecs } from "../../../../../components/form-builder/field-specs/baseEntity.fields";
import {
  EntityFormFieldSpecs,
  SpecsBaseOmit
} from "../../../../../components/form-builder/field-specs/fields.types";
import { createSpecWithFieldMeta } from "../../../../../components/form-builder/field-specs/utils/fieldMeta.utils";
import { createCustomComponentConfig } from "../../../../../components/form-builder/FormBuilder";
import LOSUserSearch from "../../../../../components/los-user/UserSearch";
import {
  booleanOptions,
  createOptionsFromEnumAllowUndefined,
  createOptionsFromMap
} from "../../../../../components/utils/formUtils";

export type BaseSchemaDealFieldSpecs = EntityFormFieldSpecs<
  SpecsBaseOmit<
    Omit<BaseSchemaDeal, "DealMetadata" | "IntegrationMetadata">
  > & {
    DealMetadata: { milestone: BaseSchemaDeal["DealMetadata"]["milestone"] };
  }
>;

export const baseSchemaDealSpecs: BaseSchemaDealFieldSpecs = {
  ...baseEntityFormFieldSpecs,
  DealMetadata: {
    milestone: {
      fieldKey: ["DealMetadata", "milestone"],
      fieldType: FieldType.SingleSelect,
      options: createOptionsFromMap(DealMilestoneType),
      label: "milestone"
    }
  },
  aggregations: {
    PerDiemPrepaidInterestAmount: {
      calculated: {
        fieldType: FieldType.Money,
        label: "Per Diem Prepaid Interest Amount",
        fieldKey: ["aggregations", "PerDiemPrepaidInterestAmount", "calculated"]
      },
      override: {
        fieldType: FieldType.Money,
        label: "Per Diem Prepaid Interest Amount",
        fieldKey: ["aggregations", "PerDiemPrepaidInterestAmount", "override"]
      },
      focused: {
        fieldKey: ["aggregations", "PerDiemPrepaidInterestAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    PrepaymentPenaltyExpirationDate: {
      calculated: {
        fieldType: FieldType.Date,
        label: "Prepayment Penalty Expiration Date",
        fieldKey: [
          "aggregations",
          "PrepaymentPenaltyExpirationDate",
          "calculated"
        ]
      },
      override: {
        fieldType: FieldType.Date,
        label: "Prepayment Penalty Expiration Date",
        fieldKey: [
          "aggregations",
          "PrepaymentPenaltyExpirationDate",
          "override"
        ]
      },
      focused: {
        fieldKey: [
          "aggregations",
          "PrepaymentPenaltyExpirationDate",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    FirstRateChangeDate: {
      calculated: {
        fieldType: FieldType.Date,
        label: "First Rate Change Date",
        fieldKey: ["aggregations", "FirstRateChangeDate", "calculated"]
      },
      override: {
        fieldType: FieldType.Date,
        label: "First Rate Change Date",
        fieldKey: ["aggregations", "FirstRateChangeDate", "override"]
      },
      focused: {
        fieldKey: ["aggregations", "FirstRateChangeDate", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    ARMFinalAdjustableRateTermMonthCount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "ARMFinalAdjustableRateTermMonthCount",
          "calculated"
        ],
        fieldType: FieldType.Number,
        label: "ARM Final Adjustable Rate Term (Months)"
      },
      override: {
        fieldKey: [
          "aggregations",
          "ARMFinalAdjustableRateTermMonthCount",
          "override"
        ],
        fieldType: FieldType.Number,
        label: "ARM Final Adjustable Rate Term (Months)"
      },
      focused: {
        fieldKey: [
          "aggregations",
          "ARMFinalAdjustableRateTermMonthCount",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    ARMInitialFixedTermMonthCount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "ARMInitialFixedTermMonthCount",
          "calculated"
        ],
        fieldType: FieldType.Number,
        label: "ARM Initial Fixed Term (Months)"
      },
      override: {
        fieldKey: ["aggregations", "ARMInitialFixedTermMonthCount", "override"],
        fieldType: FieldType.Number,
        label: "ARM Initial Fixed Term (Months)"
      },
      focused: {
        fieldKey: ["aggregations", "ARMInitialFixedTermMonthCount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    LastPaymentDueDate: {
      calculated: {
        fieldKey: ["aggregations", "LastPaymentDueDate", "calculated"],
        fieldType: FieldType.Date,
        label: "MISSINGLABEL"
      },
      override: {
        fieldKey: ["aggregations", "LastPaymentDueDate", "override"],
        fieldType: FieldType.Date,
        label: "MISSINGLABEL"
      },
      focused: {
        fieldKey: ["aggregations", "LastPaymentDueDate", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    LoanMaturityDate: {
      calculated: {
        fieldKey: ["aggregations", "LoanMaturityDate", "calculated"],
        fieldType: FieldType.Date,
        label: "Maturity Date"
      },
      override: {
        fieldKey: ["aggregations", "LoanMaturityDate", "override"],
        fieldType: FieldType.Date,
        label: "Maturity Date"
      },
      focused: {
        fieldKey: ["aggregations", "LoanMaturityDate", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    ScheduledFirstPaymentDate: {
      calculated: {
        fieldKey: ["aggregations", "ScheduledFirstPaymentDate", "calculated"],
        fieldType: FieldType.Date,
        label: "First Payment Due Date"
      },
      override: {
        fieldKey: ["aggregations", "ScheduledFirstPaymentDate", "override"],
        fieldType: FieldType.Date,
        label: "First Payment Due Date"
      },
      focused: {
        fieldKey: ["aggregations", "ScheduledFirstPaymentDate", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalLTVRatePercent: {
      calculated: {
        fieldKey: ["aggregations", "TotalLTVRatePercent", "calculated"],
        fieldType: FieldType.Percentage,
        label: "Total Loan to Value"
      },
      override: {
        fieldKey: ["aggregations", "TotalLTVRatePercent", "override"],
        fieldType: FieldType.Percentage,
        label: "Total Loan to Value"
      },
      focused: {
        fieldKey: ["aggregations", "TotalLTVRatePercent", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalLTARVRatePercent: {
      calculated: {
        fieldKey: ["aggregations", "TotalLTARVRatePercent", "calculated"],
        fieldType: FieldType.Percentage,
        label: "Total Loan to After Repair Value"
      },
      override: {
        fieldKey: ["aggregations", "TotalLTARVRatePercent", "override"],
        fieldType: FieldType.Percentage,
        label: "Total Loan to After Repair Value"
      },
      focused: {
        fieldKey: ["aggregations", "TotalLTARVRatePercent", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalHOAFeesAmount: {
      calculated: {
        fieldKey: ["aggregations", "TotalHOAFeesAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "Total Annual HOA Fees"
      },
      override: {
        fieldKey: ["aggregations", "TotalHOAFeesAmount", "override"],
        fieldType: FieldType.Money,
        label: "Total Annual HOA Fees"
      },
      focused: {
        fieldKey: ["aggregations", "TotalHOAFeesAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalInitialInsuranceEscrowAmount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "TotalInitialInsuranceEscrowAmount",
          "calculated"
        ],
        fieldType: FieldType.Money,
        label: "Total Initial Insurance Escrow"
      },
      override: {
        fieldKey: [
          "aggregations",
          "TotalInitialInsuranceEscrowAmount",
          "override"
        ],
        fieldType: FieldType.Money,
        label: "Total Initial Insurance Escrow"
      },
      focused: {
        fieldKey: [
          "aggregations",
          "TotalInitialInsuranceEscrowAmount",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalAsIsAppraisedValueAmount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "TotalAsIsAppraisedValueAmount",
          "calculated"
        ],
        fieldType: FieldType.Money,
        label: "Total As-Is Appraised Value"
      },
      override: {
        fieldKey: ["aggregations", "TotalAsIsAppraisedValueAmount", "override"],
        fieldType: FieldType.Money,
        label: "Total As-Is Appraised Value"
      },
      focused: {
        fieldKey: ["aggregations", "TotalAsIsAppraisedValueAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalBudgetAmount: {
      calculated: {
        fieldKey: ["aggregations", "TotalBudgetAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "Total Budget"
      },
      override: {
        fieldKey: ["aggregations", "TotalBudgetAmount", "override"],
        fieldType: FieldType.Money,
        label: "Total Budget"
      },
      focused: {
        fieldKey: ["aggregations", "TotalBudgetAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalFloodInsurancePremiumAmount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "TotalFloodInsurancePremiumAmount",
          "calculated"
        ],
        fieldType: FieldType.Money,
        label: "Total Flood Insurance Premium Amount"
      },
      override: {
        fieldKey: [
          "aggregations",
          "TotalFloodInsurancePremiumAmount",
          "override"
        ],
        fieldType: FieldType.Money,
        label: "Total Flood Insurance Premium Amount"
      },
      focused: {
        fieldKey: [
          "aggregations",
          "TotalFloodInsurancePremiumAmount",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalInitialTaxEscrowAmount: {
      calculated: {
        fieldKey: ["aggregations", "TotalInitialTaxEscrowAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "Total Initial Tax Escrow"
      },
      override: {
        fieldKey: ["aggregations", "TotalInitialTaxEscrowAmount", "override"],
        fieldType: FieldType.Money,
        label: "Total Initial Tax Escrow"
      },
      focused: {
        fieldKey: ["aggregations", "TotalInitialTaxEscrowAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalOutstandingLoanPayoffAmount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "TotalOutstandingLoanPayoffAmount",
          "calculated"
        ],
        fieldType: FieldType.Money,
        label: "Total Outstanding Mortgage Payoff"
      },
      override: {
        fieldKey: [
          "aggregations",
          "TotalOutstandingLoanPayoffAmount",
          "override"
        ],
        fieldType: FieldType.Money,
        label: "Total Outstanding Mortgage Payoff"
      },
      focused: {
        fieldKey: [
          "aggregations",
          "TotalOutstandingLoanPayoffAmount",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalPropertyInsurancePremiumAmount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "TotalPropertyInsurancePremiumAmount",
          "calculated"
        ],
        fieldType: FieldType.Money,
        label: "Total Property Insurance Premium Amount"
      },
      override: {
        fieldKey: [
          "aggregations",
          "TotalPropertyInsurancePremiumAmount",
          "override"
        ],
        fieldType: FieldType.Money,
        label: "Total Property Insurance Premium Amount"
      },
      focused: {
        fieldKey: [
          "aggregations",
          "TotalPropertyInsurancePremiumAmount",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalPurchasePriceAmount: {
      calculated: {
        fieldKey: ["aggregations", "TotalPurchasePriceAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "Total Purchase Price"
      },
      override: {
        fieldKey: ["aggregations", "TotalPurchasePriceAmount", "override"],
        fieldType: FieldType.Money,
        label: "Total Purchase Price"
      },
      focused: {
        fieldKey: ["aggregations", "TotalPurchasePriceAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalAssignmentFeeAmount: {
      calculated: {
        fieldKey: ["aggregations", "TotalAssignmentFeeAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "Total Assignment Fee Amount"
      },
      override: {
        fieldKey: ["aggregations", "TotalAssignmentFeeAmount", "override"],
        fieldType: FieldType.Money,
        label: "Total Assignment Fee Amount"
      },
      focused: {
        fieldKey: ["aggregations", "TotalAssignmentFeeAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalSubjectToAppraisedValueAmount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "TotalSubjectToAppraisedValueAmount",
          "calculated"
        ],
        fieldType: FieldType.Money,
        label: "Total Subject-To Appraised Value"
      },
      override: {
        fieldKey: [
          "aggregations",
          "TotalSubjectToAppraisedValueAmount",
          "override"
        ],
        fieldType: FieldType.Money,
        label: "Total Subject-To Appraised Value"
      },
      focused: {
        fieldKey: [
          "aggregations",
          "TotalSubjectToAppraisedValueAmount",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalTaxAmount: {
      calculated: {
        fieldKey: ["aggregations", "TotalTaxAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "Total Annual Tax Amount"
      },
      override: {
        fieldKey: ["aggregations", "TotalTaxAmount", "override"],
        fieldType: FieldType.Money,
        label: "Total Annual Tax Amount"
      },
      focused: {
        fieldKey: ["aggregations", "TotalTaxAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalCostAmount: {
      calculated: {
        fieldKey: ["aggregations", "TotalCostAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "Total Costs"
      },
      override: {
        fieldKey: ["aggregations", "TotalCostAmount", "override"],
        fieldType: FieldType.Money,
        label: "Total Costs"
      },
      focused: {
        fieldKey: ["aggregations", "TotalCostAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalFloodInsurancePremiumOutstandingAmount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "TotalFloodInsurancePremiumOutstandingAmount",
          "calculated"
        ],
        fieldType: FieldType.Money,
        label: "Total Flood Insurance Premium Amount Outstanding"
      },
      override: {
        fieldKey: [
          "aggregations",
          "TotalFloodInsurancePremiumOutstandingAmount",
          "override"
        ],
        fieldType: FieldType.Money,
        label: "Total Flood Insurance Premium Amount Outstanding"
      },
      focused: {
        fieldKey: [
          "aggregations",
          "TotalFloodInsurancePremiumOutstandingAmount",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    RequestedLoanAmount: {
      calculated: {
        fieldKey: ["aggregations", "RequestedLoanAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "Total Loan Amount"
      },
      focused: {
        fieldKey: ["aggregations", "RequestedLoanAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      },
      override: {
        fieldKey: ["aggregations", "RequestedLoanAmount", "override"],
        fieldType: FieldType.Money,
        label: "Total Loan Amount"
      }
    },
    TotalMonthlyMarketRent: {
      calculated: {
        fieldKey: ["aggregations", "TotalMonthlyMarketRent", "calculated"],
        fieldType: FieldType.Money,
        label: "Total Monthly Market Rent"
      },
      override: {
        fieldKey: ["aggregations", "TotalMonthlyMarketRent", "override"],
        fieldType: FieldType.Money,
        label: "Total Monthly Market Rent"
      },
      focused: {
        fieldKey: ["aggregations", "TotalMonthlyMarketRent", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    NetWireAmount: {
      calculated: {
        fieldKey: ["aggregations", "NetWireAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "Total Net Wire Amount"
      },
      override: {
        fieldKey: ["aggregations", "NetWireAmount", "override"],
        fieldType: FieldType.Money,
        label: "Total Net Wire Amount"
      },
      focused: {
        fieldKey: ["aggregations", "NetWireAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalPropertyInsurancePremiumOutstandingAmount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "TotalPropertyInsurancePremiumOutstandingAmount",
          "calculated"
        ],
        fieldType: FieldType.Money,
        label: "Total Property Insurance Premium Amount Outstanding"
      },
      override: {
        fieldKey: [
          "aggregations",
          "TotalPropertyInsurancePremiumOutstandingAmount",
          "override"
        ],
        fieldType: FieldType.Money,
        label: "Total Property Insurance Premium Amount Outstanding"
      },
      focused: {
        fieldKey: [
          "aggregations",
          "TotalPropertyInsurancePremiumOutstandingAmount",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },

    PrepaidInterestAmount: {
      calculated: {
        fieldKey: ["aggregations", "PrepaidInterestAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "Prepaid Interest Amount"
      },
      override: {
        fieldKey: ["aggregations", "PrepaidInterestAmount", "override"],
        fieldType: FieldType.Money,
        label: "Prepaid Interest Amount"
      },
      focused: {
        fieldKey: ["aggregations", "PrepaidInterestAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalNumberOfProperties: {
      calculated: {
        fieldKey: ["aggregations", "TotalNumberOfProperties", "calculated"],
        fieldType: FieldType.Number,
        label: "Number of Properties"
      },
      override: {
        fieldKey: ["aggregations", "TotalNumberOfProperties", "override"],
        fieldType: FieldType.Number,
        label: "Number of Properties"
      },
      focused: {
        fieldKey: ["aggregations", "TotalNumberOfProperties", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalAdjustedMonthlyRentAmount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "TotalAdjustedMonthlyRentAmount",
          "calculated"
        ],
        fieldType: FieldType.Money,
        label: "Total Properties Adjusted Rental Income"
      },
      override: {
        fieldKey: [
          "aggregations",
          "TotalAdjustedMonthlyRentAmount",
          "override"
        ],
        fieldType: FieldType.Money,
        label: "Total Properties Adjusted Rental Income"
      },
      focused: {
        fieldKey: ["aggregations", "TotalAdjustedMonthlyRentAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    // third party field path changes
    LoanProductType: {
      thirdParty: {
        fieldKey: ["aggregations", "LoanProductType", "thirdParty"],
        fieldType: FieldType.SingleSelect,
        label: "Loan Product",
        options: createOptionsFromMap(baseLoanProductType)
      },
      override: {
        fieldKey: ["aggregations", "LoanProductType", "override"],
        fieldType: FieldType.SingleSelect,
        label: "Loan Product",
        options: createOptionsFromMap(baseLoanProductType)
      },
      focused: {
        fieldKey: ["aggregations", "LoanProductType", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    EstimatedClosingDate: {
      thirdParty: {
        fieldKey: ["aggregations", "EstimatedClosingDate", "thirdParty"],
        fieldType: FieldType.Date,
        label: "Closing Date"
      },
      override: {
        fieldKey: ["aggregations", "EstimatedClosingDate", "override"],
        fieldType: FieldType.Date,
        label: "Closing Date"
      },
      focused: {
        fieldKey: ["aggregations", "EstimatedClosingDate", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    LoanProgramType: {
      thirdParty: {
        fieldKey: ["aggregations", "LoanProgramType", "thirdParty"],
        fieldType: FieldType.SingleSelect,
        label: "Loan Program",
        options: createOptionsFromMap(LoanProgramType)
      },
      override: {
        fieldKey: ["aggregations", "LoanProgramType", "override"],
        fieldType: FieldType.SingleSelect,
        label: "Loan Program",
        options: createOptionsFromMap(LoanProgramType)
      },
      focused: {
        fieldKey: ["aggregations", "LoanProgramType", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    LastDayOfClosingMonth: {
      calculated: {
        fieldKey: ["aggregations", "LastDayOfClosingMonth", "calculated"],
        fieldType: FieldType.Date,
        label: "Last day of Closing Month (for per-diem)"
      },
      override: {
        fieldKey: ["aggregations", "LastDayOfClosingMonth", "override"],
        fieldType: FieldType.Date,
        label: "Last day of Closing Month (for per-diem)"
      },
      focused: {
        fieldKey: ["aggregations", "LastDayOfClosingMonth", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    }
  },
  quote: {
    aggregations: {
      // Rental POST
      UnderPropertyValuationAmount: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "UnderPropertyValuationAmount",
            "calculated"
          ],
          label: "N/A",
          fieldType: FieldType.Money
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "UnderPropertyValuationAmount",
            "override"
          ],
          label: "N/A",
          fieldType: FieldType.Money
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "UnderPropertyValuationAmount",
            "focused"
          ],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PrimaryBorrowerCreditScore: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "PrimaryBorrowerCreditScore",
            "calculated"
          ],
          label: "N/A",
          fieldType: FieldType.Number
        },

        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "PrimaryBorrowerCreditScore",
            "override"
          ],
          label: "N/A",
          fieldType: FieldType.String
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "PrimaryBorrowerCreditScore",
            "focused"
          ],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      BorrowingEntity: {
        calculated: {
          fieldKey: ["quote", "aggregations", "BorrowingEntity", "calculated"],
          label: "N/A",
          fieldType: FieldType.String
        },
        override: {
          fieldKey: ["quote", "aggregations", "BorrowingEntity", "override"],
          label: "N/A",
          fieldType: FieldType.String
        },
        focused: {
          fieldKey: ["quote", "aggregations", "BorrowingEntity", "focused"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      OverPropertyValuationAmount: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "OverPropertyValuationAmount",
            "calculated"
          ],
          label: "N/A",
          fieldType: FieldType.Money
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "OverPropertyValuationAmount",
            "override"
          ],
          label: "N/A",
          fieldType: FieldType.Money
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "OverPropertyValuationAmount",
            "focused"
          ],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      TotalValuationOrPurchasePriceAmount: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "TotalValuationOrPurchasePriceAmount",
            "calculated"
          ],
          label: "Total Lesser of Purchase Price and As-Is Valuation",
          fieldType: FieldType.Money
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "TotalValuationOrPurchasePriceAmount",
            "override"
          ],
          label: "Total Lesser of Purchase Price and As-Is Valuation",
          fieldType: FieldType.Money
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "TotalValuationOrPurchasePriceAmount",
            "focused"
          ],
          label: "Total Lesser of Purchase Price and As-Is Valuation",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      USCitizenshipIndicator: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "USCitizenshipIndicator",
            "calculated"
          ],
          label: "N/A",
          fieldType: FieldType.Boolean,
          options: booleanOptions
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "USCitizenshipIndicator",
            "override"
          ],
          label: "N/A",
          fieldType: FieldType.Boolean,
          options: booleanOptions
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "USCitizenshipIndicator",
            "focused"
          ],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PrimaryGuarantor: {
        calculated: {
          fieldKey: ["quote", "aggregations", "PrimaryGuarantor", "calculated"],
          label: "N/A",
          fieldType: FieldType.String
        },
        override: {
          fieldKey: ["quote", "aggregations", "PrimaryGuarantor", "override"],
          label: "N/A",
          fieldType: FieldType.String
        },
        focused: {
          fieldKey: ["quote", "aggregations", "PrimaryGuarantor", "focused"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      SumTotalStatementQualifyingBalance: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "SumTotalStatementQualifyingBalance",
            "calculated"
          ],
          label: "N/A",
          fieldType: FieldType.Money
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "SumTotalStatementQualifyingBalance",
            "override"
          ],
          label: "N/A",
          fieldType: FieldType.Money
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "SumTotalStatementQualifyingBalance",
            "focused"
          ],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      LesserOfLotOrPurchase: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "LesserOfLotOrPurchase",
            "calculated"
          ],
          label: "Total Property Value Basis",
          fieldType: FieldType.Money
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "LesserOfLotOrPurchase",
            "override"
          ],
          label: "Total Property Value Basis",
          fieldType: FieldType.Money
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "LesserOfLotOrPurchase",
            "focused"
          ],
          label: "Total Property Value Basis",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PropertyAddressLineText: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "PropertyAddressLineText",
            "calculated"
          ],
          label: "N/A",
          fieldType: FieldType.String
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "PropertyAddressLineText",
            "override"
          ],
          label: "N/A",
          fieldType: FieldType.String
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "PropertyAddressLineText",
            "focused"
          ],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PropertyCityName: {
        calculated: {
          fieldKey: ["quote", "aggregations", "PropertyCityName", "calculated"],
          label: "N/A",
          fieldType: FieldType.String
        },
        override: {
          fieldKey: ["quote", "aggregations", "PropertyCityName", "override"],
          label: "N/A",
          fieldType: FieldType.String
        },
        focused: {
          fieldKey: ["quote", "aggregations", "PropertyCityName", "focused"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PropertyStateCode: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "PropertyStateCode",
            "calculated"
          ],
          label: "N/A",
          fieldType: FieldType.String
        },
        override: {
          fieldKey: ["quote", "aggregations", "PropertyStateCode", "override"],
          label: "N/A",
          fieldType: FieldType.String
        },
        focused: {
          fieldKey: ["quote", "aggregations", "PropertyStateCode", "focused"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PropertyPostalCode: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "PropertyPostalCode",
            "calculated"
          ],
          label: "N/A",
          fieldType: FieldType.String
        },
        override: {
          fieldKey: ["quote", "aggregations", "PropertyPostalCode", "override"],
          label: "N/A",
          fieldType: FieldType.String
        },
        focused: {
          fieldKey: ["quote", "aggregations", "PropertyPostalCode", "focused"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PropertyType: {
        calculated: {
          fieldKey: ["quote", "aggregations", "PropertyType", "calculated"],
          label: "N/A",
          fieldType: FieldType.String
        },
        override: {
          fieldKey: ["quote", "aggregations", "PropertyType", "override"],
          label: "N/A",
          fieldType: FieldType.String
        },
        focused: {
          fieldKey: ["quote", "aggregations", "PropertyType", "focused"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      OriginalPurchaseDate: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "OriginalPurchaseDate",
            "calculated"
          ],
          label: "N/A",
          fieldType: FieldType.Date
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "OriginalPurchaseDate",
            "override"
          ],
          label: "N/A",
          fieldType: FieldType.Date
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "OriginalPurchaseDate",
            "focused"
          ],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      BorrowerTier: {
        calculated: {
          fieldKey: ["quote", "aggregations", "BorrowerTier", "calculated"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromMap(FixNFlipNBridgePlusTierType)
        },
        override: {
          fieldKey: ["quote", "aggregations", "BorrowerTier", "override"],
          label: "N/A",
          fieldType: FieldType.String,
          options: createOptionsFromMap(FixNFlipNBridgePlusTierType)
        },
        focused: {
          fieldKey: ["quote", "aggregations", "BorrowerTier", "focused"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      }
    }
  },
  LOCAvailableAmount: {
    fieldType: FieldType.Money,
    label: "Unpaid Principal Balance",
    fieldKey: ["LOCAvailableAmount"]
  },
  LOCExpirationDate: {
    fieldType: FieldType.Date,
    label: "LOC Expiration Date",
    fieldKey: ["LOCExpirationDate"]
  },
  CashOutAmount: {
    fieldType: FieldType.Money,
    label: "Total Cash Out Amount",
    fieldKey: ["CashOutAmount"]
  },
  DownPaymentAmount: {
    fieldType: FieldType.Money,
    label: "Down Payment Amount",
    fieldKey: ["DownPaymentAmount"]
  },
  AggregateOccupancyPercent: {
    fieldType: FieldType.Percentage,
    label: "Aggregated Occupancy Percent",
    fieldKey: ["AggregateOccupancyPercent"]
  },
  PolicySectionNumber: {
    fieldType: FieldType.String,
    label: "Policy Section",
    fieldKey: ["PolicySectionNumber"]
  },
  LenderIdentifier: {
    fieldType: FieldType.SingleSelect,
    label: "Lender Company Name",
    fieldKey: ["LenderIdentifier"]
  },
  LoanIdentifier: {
    fieldType: FieldType.String,
    label: "Loan Number",
    fieldKey: ["LoanIdentifier"]
  },
  ApplicationReceivedDate: {
    fieldType: FieldType.Date,
    label: "Application Date",
    fieldKey: ["ApplicationReceivedDate"]
  },
  LOCIssuanceDate: {
    fieldType: FieldType.Date,
    label: "Line of Credit Issuance",
    fieldKey: ["LOCIssuanceDate"]
  },
  PreliminaryUnderwritingDecisionDate: {
    fieldType: FieldType.Date,
    label: "Preliminary Underwriting Decision",
    fieldKey: ["PreliminaryUnderwritingDecisionDate"]
  },
  FinalUnderwritingDecision: {
    fieldType: FieldType.String,
    label: "Final Underwriting Decision",
    fieldKey: ["FinalUnderwritingDecision"]
  },
  InitialTaskGenerationDate: {
    fieldType: FieldType.Date,
    label: "Initial Task Generation",
    fieldKey: ["InitialTaskGenerationDate"]
  },
  ClearToCloseDate: {
    fieldType: FieldType.Date,
    label: "Clear to Close",
    fieldKey: ["ClearToCloseDate"]
  },
  LoanName: {
    fieldType: FieldType.String,
    label: "Loan Name",
    fieldKey: ["LoanName"]
  },
  Channel: {
    fieldType: FieldType.SingleSelect,
    label: "Sales Channel",
    fieldKey: ["Channel"],
    options: createOptionsFromMap(baseChannelType)
  },
  RealEstateProgramType: {
    fieldType: FieldType.SingleSelect,
    label: "Real Estate Program",
    fieldKey: ["RealEstateProgramType"],
    options: createOptionsFromMap(RealEstateProgramType)
  },
  TotalPropertyCount: {
    fieldType: FieldType.Integer,
    label: "Number of Properties",
    fieldKey: ["TotalPropertyCount"]
  },
  WireReleaseDate: {
    fieldType: FieldType.Date,
    label: "Wire Release Date",
    fieldKey: ["WireReleaseDate"]
  },
  LoanFundingDate: {
    fieldType: FieldType.Date,
    label: "Close of Escrow Date",
    fieldKey: ["LoanFundingDate"]
  },
  PaymentDueDay: {
    fieldType: FieldType.SingleSelect,
    label: "Payment Due Day",
    fieldKey: ["PaymentDueDay"],
    options: createOptionsFromMap(basePaymentDueDay)
  },
  NextPaymentDueDate: {
    fieldType: FieldType.Date,
    label: "Next Payment Due Date",
    fieldKey: ["NextPaymentDueDate"]
  },
  LoanPaymentType: {
    fieldType: FieldType.SingleSelect,
    label: "Payment Type",
    fieldKey: ["LoanPaymentType"],
    options: createOptionsFromMap(baseLoanPayment)
  },
  LoanTermPeriodMonthCount: {
    fieldType: FieldType.SingleSelect,
    label: "Loan Term (Months)",
    fieldKey: ["LoanTermPeriodMonthCount"],
    options: createOptionsFromMap(baseLoanTermPeriodMonthCount)
  },
  LoanAmortizationPeriodMonthCount: {
    fieldType: FieldType.Integer,
    label: "Amortization Period (Months)",
    fieldKey: ["LoanAmortizationPeriodMonthCount"]
  },
  LoanInterestOnlyPeriodMonthCount: {
    fieldType: FieldType.Integer,
    label: "IO Period (Months)",
    fieldKey: ["LoanInterestOnlyPeriodMonthCount"]
  },
  InterestAccrualMethodType: {
    fieldType: FieldType.SingleSelect,
    label: "Interest Rate Accrual Method",
    fieldKey: ["InterestAccrualMethodType"],
    options: createOptionsFromMap(InterestAccrualMethodType)
  },
  LoanAmortizationPeriodYearCount: {
    fieldType: FieldType.Year,
    label: "Amortization Period (Years)",
    fieldKey: ["LoanAmortizationPeriodYearCount"]
  },
  IndexSourceType: {
    fieldType: FieldType.SingleSelect,
    label: "Interest Index",
    fieldKey: ["IndexSourceType"],
    options: createOptionsFromMap(IndexSourceType)
  },
  CapitalStructureType: {
    fieldType: FieldType.SingleSelect,
    label: "Cap Structure",
    fieldKey: ["CapitalStructureType"],
    options: createOptionsFromMap(CapitalStructureType)
  },
  MarginRatePercent: {
    fieldType: FieldType.PercentageThreeDecimal,
    label: "Margin",
    fieldKey: ["MarginRatePercent"]
  },
  FloorRatePercent: {
    fieldType: FieldType.PercentageThreeDecimal,
    label: "Floor",
    fieldKey: ["FloorRatePercent"]
  },
  LifetimeCapRatePercent: {
    fieldType: FieldType.PercentageThreeDecimal,
    label: "Lifetime Cap",
    fieldKey: ["LifetimeCapRatePercent"]
  },
  ARMRepriceRoundingRatePercentType: {
    fieldType: FieldType.SingleSelect,
    label: "ARM Reprice Rounding %",
    fieldKey: ["ARMRepriceRoundingRatePercentType"],
    options: createOptionsFromMap(ARMRepriceRoundingRatePercentType)
  },
  ARMRepriceRoundingMethodType: {
    fieldType: FieldType.SingleSelect,
    label: "ARM Reprice Rounding Method",
    fieldKey: ["ARMRepriceRoundingMethodType"],
    options: createOptionsFromMap(ARMRepriceRoundingMethodType)
  },
  ARMRepriceLookbackType: {
    fieldType: FieldType.SingleSelect,
    label: "ARM Reprice Lookback",
    fieldKey: ["ARMRepriceLookbackType"],
    options: createOptionsFromMap(ARMRepriceLookbackType)
  },
  UCCExpirationDate: {
    fieldType: FieldType.Date,
    label: "UCC Expiration Date",
    fieldKey: ["UCCExpirationDate"]
  },
  WarehouseBankType: {
    fieldType: FieldType.SingleSelect,
    label: "Warehouse Bank",
    fieldKey: ["WarehouseBankType"]
  },
  WarehouseBankStatusType: {
    fieldType: FieldType.SingleSelect,
    label: "Warehouse Status",
    fieldKey: ["WarehouseBankStatusType"],
    options: createOptionsFromMap(WarehouseBankStatusType)
  },
  ACHElectedIndicator: {
    fieldType: FieldType.Boolean,
    label: "ACH Elected?",
    fieldKey: ["ACHElectedIndicator"],
    options: booleanOptions
  },
  SellerHUDAddedIndicator: {
    fieldType: FieldType.Boolean,
    label: "Seller HUD Added?",
    fieldKey: ["SellerHUDAddedIndicator"],
    options: booleanOptions
  },
  PurchaserWarrantyDeedAddedIndicator: {
    fieldType: FieldType.Boolean,
    label: "Purchaser Warranty Deed Added?",
    fieldKey: ["PurchaserWarrantyDeedAddedIndicator"],
    options: booleanOptions
  },
  HaircutAmount: {
    fieldType: FieldType.Money,
    label: "Haircut Amount",
    fieldKey: ["HaircutAmount"]
  },
  CollateralPackageStatusType: {
    fieldType: FieldType.SingleSelect,
    label: "Collateral Package Status",
    fieldKey: ["CollateralPackageStatusType"],
    options: createOptionsFromMap(CollateralPackageStatusType)
  },
  CollateralPackageTrackingNumber: {
    fieldType: FieldType.String,
    label: "Collateral Package Tracking Information",
    fieldKey: ["CollateralPackageTrackingNumber"]
  },
  Ledger: {
    AppraisalDepositAmount: {
      fieldType: FieldType.Money,
      label: "Appraisal Deposit",
      fieldKey: ["Ledger", "AppraisalDepositAmount"]
    },
    AppraisalFeeAmount: {
      fieldType: FieldType.Money,
      label: "Appraisal Fee",
      fieldKey: ["Ledger", "AppraisalFeeAmount"]
    },
    CreditReportFeeAmount: {
      fieldType: FieldType.Money,
      label: "Credit Report Fee",
      fieldKey: ["Ledger", "CreditReportFeeAmount"]
    },

    InterestReserveAmount: {
      fieldType: FieldType.Money,
      label: "Interest Reserve Amount",
      fieldKey: ["Ledger", "InterestReserveAmount"]
    },
    NewYorkProcessingFeeAmount: {
      fieldType: FieldType.Money,
      label: "New York Processing Fee",
      fieldKey: ["Ledger", "NewYorkProcessingFeeAmount"]
    },
    CondoCertificationFeeAmount: {
      fieldType: FieldType.Money,
      label: "Condo Certification Fee",
      fieldKey: ["Ledger", "CondoCertificationFeeAmount"]
    },
    ContractorReviewFeeAmount: {
      fieldType: FieldType.Money,
      label: "Contractor Review Fee",
      fieldKey: ["Ledger", "ContractorReviewFeeAmount"]
    },
    FeasibilityReviewFeeAmount: {
      fieldType: FieldType.Money,
      label: "Feasibility Review Fee",
      fieldKey: ["Ledger", "FeasibilityReviewFeeAmount"]
    },
    LenderCounselFeeAmount: {
      fieldType: FieldType.Money,
      label: "Lender Counsel Fee",
      fieldKey: ["Ledger", "LenderCounselFeeAmount"]
    }
  },
  LienPosition: {
    fieldType: FieldType.Number,
    label: "Lien Position",
    fieldKey: ["LienPosition"]
  },
  UCCFiledIndicator: {
    fieldType: FieldType.Boolean,
    label: "UCC Filed?",
    fieldKey: ["UCCFiledIndicator"],
    options: booleanOptions
  },
  TitleCompany: {
    AddressLineText: {
      ...addressFieldSpecs.AddressLineText,
      label: "Closing Office Address Line 1",
      fieldKey: ["TitleCompany", "AddressLineText"]
    },
    AddressUnitIdentifier: {
      ...addressFieldSpecs.AddressUnitIdentifier,
      label: "Closing Office Unit Identifier",
      fieldKey: ["TitleCompany", "AddressUnitIdentifier"]
    },
    CityName: {
      ...addressFieldSpecs.CityName,
      label: "Closing Office City Name",
      fieldKey: ["TitleCompany", "CityName"]
    },
    CountyName: {
      ...addressFieldSpecs.CountyName,
      label: "Closing Office County Name",
      fieldKey: ["TitleCompany", "CountyName"]
    },
    PostalCode: {
      ...addressFieldSpecs.PostalCode,
      label: "Closing Office Zip Code",
      fieldKey: ["TitleCompany", "PostalCode"]
    },
    StateCode: {
      ...addressFieldSpecs.StateCode,
      label: "Closing Office State Code",
      fieldKey: ["TitleCompany", "StateCode"]
    },
    StateName: {
      ...addressFieldSpecs.StateName,
      label: "Closing Office State Name",
      fieldKey: ["TitleCompany", "StateName"]
    },
    Attorney: {
      FullName: {
        fieldType: FieldType.String,
        label: "Attorney",
        fieldKey: ["TitleCompany", "Attorney", "FullName"]
      }
    },
    FullName: {
      fieldType: FieldType.String,
      label: "Closing Office Company Name",
      fieldKey: ["TitleCompany", "FullName"]
    },
    ClosingAgentContactPointTelephoneValue: {
      fieldType: FieldType.Phone,
      label: "Closing Agent Phone Number",
      fieldKey: ["TitleCompany", "ClosingAgentContactPointTelephoneValue"]
    },
    ClosingAgentContactPointEmailValue: {
      fieldType: FieldType.Email,
      label: "Closing Agent Email Address",
      fieldKey: ["TitleCompany", "ClosingAgentContactPointEmailValue"]
    },
    ClosingAgentFirstName: {
      fieldType: FieldType.String,
      label: "Closing Agent Contact First Name",
      fieldKey: ["TitleCompany", "ClosingAgentFirstName"]
    },
    ClosingAgentLastName: {
      fieldType: FieldType.String,
      label: "Closing Agent Contact Last Name",
      fieldKey: ["TitleCompany", "ClosingAgentLastName"]
    },
    TitleInsuranceUnderwriter: {
      fieldType: FieldType.String,
      label: "Title Insurance Underwriter",
      fieldKey: ["TitleCompany", "TitleInsuranceUnderwriter"]
    }
  },
  BlendedLTCLoanAmount: {
    fieldType: FieldType.Money,
    label: "Blended LTC Maximum Loan Amount",
    fieldKey: ["BlendedLTCLoanAmount"]
  },
  ConstructionLTCRatePercent: {
    fieldType: FieldType.Percentage,
    label: "Max Construction Budget Loan to Cost",
    fieldKey: ["ConstructionLTCRatePercent"]
  },
  TotalPropertyValuationAmount: {
    fieldType: FieldType.Money,
    label: "Value",
    fieldKey: ["TotalPropertyValuationAmount"]
  },
  MaxARVAmount: {
    fieldType: FieldType.Money,
    label: "After Repair Value",
    fieldKey: ["MaxARVAmount"]
  },
  InitialAdvancedFundAmount: {
    fieldType: FieldType.Money,
    label: "Initially Advanced Funds",
    fieldKey: ["InitialAdvancedFundAmount"]
  },
  TotalLoanFeesAndClosingCostAmount: {
    fieldType: FieldType.Money,
    label: "Total Loan Fees & Closing Costs",
    fieldKey: ["TotalLoanFeesAndClosingCostAmount"]
  },
  ClosingAnalystUserId: {
    fieldType: FieldType.SingleSelect,
    label: "Closing Analyst",
    fieldKey: ["ClosingAnalystUserId"],
    componentOverride: createCustomComponentConfig({
      component: LOSUserSearch,
      props: {
        showRoles: [UserRoleType.ClosingAnalyst]
      }
    })
  },
  PromissoryNoteComments: {
    fieldType: FieldType.RichText,
    label: "Promissory Note Comments",
    fieldKey: ["PromissoryNoteComments"]
  },
  ShippingCompanyType: {
    fieldType: FieldType.SingleSelect,
    label: "Shipping Company",
    fieldKey: ["ShippingCompanyType"],
    options: createOptionsFromMap(ShippingCompanyType)
  },
  NoteToWarehouseTrackingNumber: {
    fieldType: FieldType.String,
    label: "Note to Warehouse Tracking Number",
    fieldKey: ["NoteToWarehouseTrackingNumber"]
  },
  SettlementStatementFileNumber: {
    fieldType: FieldType.String,
    label: "Settlement Statement File Number",
    fieldKey: ["SettlementStatementFileNumber"]
  },
  AllongeOrAssignmentSentToWarehouseIndicator: {
    fieldType: FieldType.Boolean,
    label: "Allonge/Assignment Sent to Warehouse",
    fieldKey: ["AllongeOrAssignmentSentToWarehouseIndicator"],
    options: booleanOptions
  },
  Lender: {
    AddressLineText: {
      ...addressFieldSpecs.AddressLineText,
      label: "Lender Address Line 1",
      fieldKey: ["Lender", "AddressLineText"]
    },
    AddressUnitIdentifier: {
      ...addressFieldSpecs.AddressUnitIdentifier,
      label: "Lender Unit Identifier",
      fieldKey: ["Lender", "AddressUnitIdentifier"]
    },
    CityName: {
      ...addressFieldSpecs.CityName,
      label: "Lender City Name",
      fieldKey: ["Lender", "CityName"]
    },
    CountyName: {
      ...addressFieldSpecs.CountyName,
      label: "Lender County Name",
      fieldKey: ["Lender", "CountyName"]
    },
    PostalCode: {
      ...addressFieldSpecs.PostalCode,
      label: "Lender Zip Code",
      fieldKey: ["Lender", "PostalCode"]
    },
    StateCode: {
      ...addressFieldSpecs.StateCode,
      label: "Lender State Code",
      fieldKey: ["Lender", "StateCode"]
    },
    StateName: {
      ...addressFieldSpecs.StateName,
      label: "Lender State Name",
      fieldKey: ["Lender", "StateName"]
    },
    FormationState: {
      fieldType: FieldType.SingleSelect,
      label: "Lender Formation State Name",
      fieldKey: ["Lender", "FormationState"],
      options: createOptionsFromMap(StateName)
    },
    EntityType: {
      fieldType: FieldType.SingleSelect,
      label: "Lender Entity Type",
      fieldKey: ["Lender", "EntityType"],
      options: createOptionsFromMap(LenderEntityType)
    }
  },
  PropertyModelType: {
    fieldType: FieldType.SingleSelect,
    label: "Property Model",
    fieldKey: ["PropertyModelType"],
    options: createOptionsFromMap(PropertyModelType)
  },
  GracePeriodDays: {
    fieldType: FieldType.Number,
    label: "Grace Period Days",
    fieldKey: ["GracePeriodDays"]
  },
  Exceptions: {
    ExceptionApprovedBy: {
      fieldType: FieldType.SingleSelect,
      label: "Exception Approved By",
      fieldKey: ["Exceptions", "ExceptionApprovedBy"],
      options: createOptionsFromMap(DealExceptionApprovedBy)
    },
    ExceptionCommentary: {
      fieldType: FieldType.RichText,
      label: "Exception Commentary",
      fieldKey: ["Exceptions", "ExceptionCommentary"]
    },
    ExceptionType: {
      fieldType: FieldType.MultiSelect,
      label: "Exception Type",
      fieldKey: ["Exceptions", "ExceptionType"],
      options: createOptionsFromMap(ExceptionType)
    }
  },
  NotaryUserId: {
    fieldType: FieldType.SingleSelect,
    label: "Notary",
    fieldKey: ["NotaryUserId"],
    componentOverride: createCustomComponentConfig({
      component: LOSUserSearch,
      props: {
        showRoles: [UserRoleType.Notary]
      }
    })
  },
  NotaryExpirationDate: {
    fieldType: FieldType.Date,
    label: "Notary Expiration Date",
    fieldKey: ["NotaryExpirationDate"]
  },
  LoanPackageTrackingNumber: {
    fieldType: FieldType.String,
    label: "Physical Executed Loan Package Tracking Number",
    fieldKey: ["LoanPackageTrackingNumber"]
  },
  LoanPackageNoteTrackingNumber: {
    fieldType: FieldType.String,
    label: "Note to Warehouse Tracking Number",
    fieldKey: ["LoanPackageNoteTrackingNumber"]
  },
  LoanPackageInternationalExecutionIndicator: {
    fieldType: FieldType.Boolean,
    label: "International Execution?",
    fieldKey: ["LoanPackageInternationalExecutionIndicator"],
    options: booleanOptions
  },
  LoanPackageNoteReceivedByLenderIndicator: {
    fieldType: FieldType.Boolean,
    label: "Note Received by Lima One?",
    fieldKey: ["LoanPackageNoteReceivedByLenderIndicator"],
    options: booleanOptions
  },
  UnderwriterApprovalStatus: {
    fieldType: FieldType.SingleSelect,
    label: "Underwriter Approval Status",
    fieldKey: ["UnderwriterApprovalStatus"],
    options: createOptionsFromMap(UnderwriterApprovalStatusType)
  },
  UnderwriterApprovalComments: {
    fieldType: FieldType.RichText,
    label: "Underwriter Review Comments",
    fieldKey: ["UnderwriterApprovalComments"]
  },
  AssetSummaryReportIndicator: {
    fieldType: FieldType.Boolean,
    label: "Asset Summary Report",
    fieldKey: ["AssetSummaryReportIndicator"],
    options: booleanOptions
  },
  CreditMemorandumIndicator: {
    fieldType: FieldType.Boolean,
    label: "Credit Memorandum",
    fieldKey: ["CreditMemorandumIndicator"],
    options: booleanOptions
  },
  CreditReviewCommitteeApprovalIndicator: {
    fieldType: FieldType.Boolean,
    label: "Credit Review Committee Approval",
    fieldKey: ["CreditReviewCommitteeApprovalIndicator"],
    options: booleanOptions
  },
  SubmissionNotes: {
    fieldType: FieldType.RichText,
    label: "Submission Notes",
    fieldKey: ["SubmissionNotes"]
  },
  PricingEngineExceptionStatus: {
    fieldType: FieldType.SingleSelect,
    label: "Exception Status",
    fieldKey: ["PricingEngineExceptionStatus"],
    options: createOptionsFromMap(PricingEngineExceptionStatusType)
  },
  OperationsDepartment: {
    fieldType: FieldType.SingleSelect,
    label: "Operations Department",
    fieldKey: ["OperationsDepartment"],
    options: createOptionsFromMap(DealOperationsDepartmentType)
  },
  PropertyInsurancePremiumPaymentType: {
    fieldType: FieldType.SingleSelect,
    label: "Property Insurance Premium Payment",
    fieldKey: ["PropertyInsurancePremiumPaymentType"],
    options: createOptionsFromMap(PropertyInsurancePremiumPaymentType)
  },
  FloodInsurancePremiumPaymentType: {
    fieldType: FieldType.SingleSelect,
    label: "Flood Insurance Premium Payment",
    fieldKey: ["FloodInsurancePremiumPaymentType"],
    options: createOptionsFromMap(baseFloodInsurancePremiumPayment)
  },
  InterestReserveDepositAmount: {
    fieldType: FieldType.Money,
    label: "Interest Reserve Deposit",
    fieldKey: ["InterestReserveDepositAmount"]
  },
  TitleCommitment: {
    AllSubjectPropertiesIncludedIndicator: {
      fieldType: FieldType.Boolean,
      label: "All Subject Properties Included?",
      fieldKey: ["TitleCommitment", "AllSubjectPropertiesIncludedIndicator"],
      options: booleanOptions
    },
    PropertyRightsOwnershipType: {
      fieldKey: ["TitleCommitment", "PropertyRightsOwnershipType"],
      fieldType: FieldType.SingleSelect,
      label: "Ownership (Property Rights)",
      options: createOptionsFromMap(PropertyRightsOwnershipType)
    },
    TitleCommitmentComments: {
      fieldType: FieldType.RichText,
      label: "Title Commitment Comments",
      fieldKey: ["TitleCommitment", "TitleCommitmentComments"]
    },
    TitleEffectiveDate: {
      fieldType: FieldType.Date,
      label: "Title Commitment Effective Date",
      fieldKey: ["TitleCommitment", "TitleEffectiveDate"]
    },
    TitleExpirationDate: {
      fieldType: FieldType.Date,
      label: "Title Commitment Expiration Date",
      fieldKey: ["TitleCommitment", "TitleExpirationDate"]
    },
    TitlePolicyAmount: {
      fieldType: FieldType.Money,
      label: "Policy Amount",
      fieldKey: ["TitleCommitment", "TitlePolicyAmount"]
    },
    TitleVestingType: {
      fieldType: FieldType.SingleSelect,
      label: "Title Vesting",
      fieldKey: ["TitleCommitment", "TitleVestingType"],
      options: createOptionsFromMap(TitleVestingType)
    }
  },
  SettlementStatementComments: {
    fieldType: FieldType.RichText,
    label: "Settlement Statement Comments",
    fieldKey: ["SettlementStatementComments"]
  },
  EOExpirationDate: {
    fieldType: FieldType.Date,
    label: "E&O Expiration Date",
    fieldKey: ["EOExpirationDate"]
  },
  WireABARoutingNumber: {
    fieldType: FieldType.String,
    label: "Wire Info - ABA/Routing Number",
    fieldKey: ["WireABARoutingNumber"]
  },
  WireAccountNumber: {
    fieldType: FieldType.String,
    label: "Wire Info - Account Number",
    fieldKey: ["WireAccountNumber"]
  },
  WireBankName: {
    fieldType: FieldType.String,
    label: "Wire Info - Bank Name",
    fieldKey: ["WireBankName"]
  },
  WireAccountName: {
    fieldType: FieldType.String,
    label: "Wire Info - Name on Account",
    fieldKey: ["WireAccountName"]
  },
  TitleFormsComments: {
    fieldType: FieldType.RichText,
    label: "Title Forms Comments",
    fieldKey: ["TitleFormsComments"]
  },
  LoanPurposeType: {
    fieldType: FieldType.SingleSelect,
    label: "Loan Purpose",
    fieldKey: ["LoanPurposeType"],
    options: createOptionsFromMap(LoanPurposeType)
  },
  PortfolioLoanIndicator: {
    fieldType: FieldType.Boolean,
    label: "Portfolio Loan?",
    fieldKey: ["PortfolioLoanIndicator"],
    options: booleanOptions
  },
  RateLockStartDate: {
    fieldType: FieldType.Date,
    label: "Rate Lock Start Date",
    fieldKey: ["RateLockStartDate"]
  },
  RateLockEndDate: {
    fieldType: FieldType.Date,
    label: "Rate Lock End Date",
    fieldKey: ["RateLockEndDate"]
  },
  TotalCashToFromBorrower: {
    fieldType: FieldType.SignedMoney,
    label: "Total Cash To/From Borrower",
    fieldKey: ["TotalCashToFromBorrower"]
  },
  CashFromBorrowerAmount: {
    fieldType: FieldType.Money,
    label: "Cash From Borrower",
    fieldKey: ["CashFromBorrowerAmount"]
  },
  CashToBorrowerAmount: {
    fieldType: FieldType.Money,
    label: "Cash To Borrower",
    fieldKey: ["CashToBorrowerAmount"]
  },
  PrimaryBorrowers: {
    fieldType: FieldType.Array,
    label: "Primary borrowers",
    fieldKey: ["PrimaryBorrowers"]
  },
  SecondaryBorrowers: {
    fieldType: FieldType.Array,
    label: "borrowers",
    fieldKey: ["SecondaryBorrowers"]
  },
  PrimarySponsors: {
    fieldType: FieldType.Array,
    label: "Primary sponsors",
    fieldKey: ["PrimarySponsors"]
  },
  SecondarySponsors: {
    fieldType: FieldType.Array,
    label: "sponsors",
    fieldKey: ["SecondarySponsors"]
  },
  mailAwayIndicator: {
    fieldKey: ["mailAwayIndicator"],
    fieldType: FieldType.Boolean,
    label: "Mail Away?",
    options: booleanOptions
  },
  fundingAgentEmailAddress: {
    fieldKey: ["fundingAgentEmailAddress"],
    fieldType: FieldType.Email,
    label: "Funding Agent Email Address"
  }
};

export const fullBaseSchemaDealSpecs = createSpecWithFieldMeta({
  spec: baseSchemaDealSpecs
});
