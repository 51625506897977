import { BaseSchemaIndividualParty } from "@elphi/types/entities/base/party";
import { FeatureFlag } from "@elphi/utils";
import { OnChangeInput } from "../../../../../components/form-builder/FormBuilder";
import { EntityFormFieldSpecs } from "../../../../../components/form-builder/field-specs/fields.types";
import { ssnInputFormatter } from "../../../../../components/form-builder/formatters/inputs.formatter";
import {
  getTaxIdentifierFormatter,
  getTaxIdentifierValidator
} from "../../../../../components/utils/partyUtils";
import { addRuleToInput, buildSection } from "../../../utils/formBuilder.utils";
import {
  backgroundReportDate,
  backgroundReportExpirationDate,
  bankruptcyIndicator,
  convictionIndicator,
  creditReportNotes,
  estimatedCreditScore,
  experienceNotes,
  fixNFlipNBridgePlusTier,
  flipsAverageProjectDuration,
  flipsAverageSalePrice,
  flipsCumulativeSalePrice,
  foreclosureIndicator,
  groupId,
  individualBirthDate,
  individualCitizenshipCountry,
  individualCompletedExits,
  individualContactPointRoleType,
  individualFirstName,
  individualGovernmentIssuedIDExpirationDate,
  individualLastName,
  individualMiddleName,
  individualUSCitizenshipIndicator,
  loanAmountInProcess,
  locExpirationDateAggregation,
  locStatusType,
  mortgageDelinquentIndicator,
  newBuildsAverageProjectDuration,
  newBuildsAverageSalePrice,
  newBuildsCumulativeSalePrice,
  newConstructionEligibilityIndicator,
  numberOfFlips,
  numberOfNewBuilds,
  numberOfTransactions,
  operationsDepartment,
  outstandingJudgmentsIndicator,
  outstandingLawsuitsIndicator,
  partyContactPointEmailValue,
  partyContactPointTelephoneValue,
  statedCashOnHandAmount,
  taxpayerIdentifierType,
  taxpayerIdentifierValue,
  totalApprovedLOC,
  totalStatementQualifyingBalance,
  transactionsAverageProjectDuration,
  transactionsAverageSalePrice,
  transactionsCumulativeSalePrice
} from "../../inputs/party.inputs";
import {
  BaseSchemaIndividualPartySections,
  BaseSchemaSectionsBuilders
} from "../../types";

const individualTaxIdentifierSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaIndividualParty>;
    onChange: (v: OnChangeInput) => void;
    options?: {
      gates?: Partial<{ [key in FeatureFlag]: boolean }>;
    };
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Identifier",
      inputs: [
        taxpayerIdentifierType,
        addRuleToInput({
          input: taxpayerIdentifierValue,
          state: r.state,
          rules: [
            {
              field: "formatter",
              formatter: () =>
                getTaxIdentifierFormatter(r.state?.TaxpayerIdentifierType)
            },
            {
              field: "validation",
              validation:
                getTaxIdentifierValidator(r.state?.TaxpayerIdentifierType) ||
                (() => false)
            }
          ]
        }),
        addRuleToInput({
          input: groupId,
          rules: [
            {
              field: "isHidden",
              predicate: (_) =>
                !r.options?.gates?.esd_2826_organization_management
            }
          ],
          state: r.state
        })
      ],
      fieldSpecs
    });

const individualInfoSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaIndividualParty>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Individual Info",
      inputs: [
        individualFirstName,
        individualMiddleName,
        individualLastName,
        individualBirthDate,
        individualGovernmentIssuedIDExpirationDate,
        backgroundReportDate,
        backgroundReportExpirationDate,
        individualCitizenshipCountry,
        individualUSCitizenshipIndicator,
        individualContactPointRoleType,
        partyContactPointTelephoneValue,
        partyContactPointEmailValue,
        operationsDepartment,
        totalStatementQualifyingBalance,
        creditReportNotes,
        locStatusType,
        totalApprovedLOC,
        loanAmountInProcess,
        locExpirationDateAggregation
      ],
      fieldSpecs
    });

const partyShortTermExperienceSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaIndividualParty>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Short-Term Experience",
      inputs: [
        individualCompletedExits,
        numberOfTransactions,
        transactionsCumulativeSalePrice,
        transactionsAverageProjectDuration,
        transactionsAverageSalePrice,
        numberOfFlips,
        flipsCumulativeSalePrice,
        flipsAverageProjectDuration,
        flipsAverageSalePrice,
        numberOfNewBuilds,
        newBuildsCumulativeSalePrice,
        newBuildsAverageProjectDuration,
        newBuildsAverageSalePrice,
        experienceNotes,
        fixNFlipNBridgePlusTier,
        newConstructionEligibilityIndicator
      ],
      fieldSpecs
    });

const individualPartyDeclarationsSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaIndividualParty>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Declarations",
      inputs: [
        estimatedCreditScore,
        convictionIndicator,
        outstandingLawsuitsIndicator,
        outstandingJudgmentsIndicator,
        bankruptcyIndicator,
        foreclosureIndicator,
        mortgageDelinquentIndicator,
        statedCashOnHandAmount
      ],
      fieldSpecs
    });

const createIndividualSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaIndividualParty>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "",
      inputs: [
        individualFirstName,
        individualMiddleName,
        individualLastName,
        { ...taxpayerIdentifierValue, formatter: ssnInputFormatter }
      ],
      hideAttachedComponent: true,
      fieldSpecs
    });

export const individualPartySectionBuilders: BaseSchemaSectionsBuilders<BaseSchemaIndividualPartySections> =
  {
    individualTaxIdentifierSection,
    individualInfoSection,
    partyShortTermExperienceSection,
    individualPartyDeclarationsSection,
    createIndividualSection
  };
