import { BaseSchemaDeal } from "@elphi/types/entities/base/deal";
import {
  hideFixNFlip,
  hideFixNFlipOrEmpty,
  hideRental30
} from "../../../../components/application/sections/sections.utils";
import { OnChangeInput } from "../../../../components/form-builder/FormBuilder";
import { EntityFormFieldSpecs } from "../../../../components/form-builder/field-specs/fields.types";
import {
  addRuleToInput,
  addRulesToInputs,
  buildSection
} from "../../utils/formBuilder.utils";
import {
  achElectedIndicator,
  armFinalAdjustableRateTermMonthCount,
  armInitialFixedTermMonthCount,
  armRepriceLookbackType,
  armRepriceRoundingMethodType,
  armRepriceRoundingRatePercentType,
  capitalStructureType,
  cashFromBorrowerAmount,
  cashToBorrowerAmount,
  channel,
  closingAgentFirstName,
  closingAgentLastName,
  closingAnalystUserId,
  condoCertificationFeeAmount,
  contractorReviewFeeAmount,
  creditReportFeeAmount,
  downPaymentAmount,
  eoExpirationDate,
  estimatedClosingDate,
  exceptionApprovedBy,
  exceptionCommentary,
  exceptionType,
  feasibilityReviewFeeAmount,
  firstRateChangeDate,
  floodInsurancePremiumPaymentType,
  fundingAgentEmailAddress,
  gracePeriodDays,
  haircutAmount,
  indexSourceType,
  initialAdvancedFundAmount,
  interestAccrualMethodType,
  interestReserveDepositAmount,
  lastDayOfClosingMonth,
  lenderCounselFeeAmount,
  lenderIdentifier,
  lesserOfLotOrPurchase,
  loanAmortizationPeriodMonthCount,
  loanInterestOnlyPeriodMonthCount,
  loanMaturityDate,
  loanName,
  loanPackageInternationalExecutionIndicator,
  loanPackageNoteReceivedByLenderIndicator,
  loanPackageNoteTrackingNumber,
  loanPackageTrackingNumber,
  loanPaymentType,
  loanProductType,
  loanProgramType,
  loanPurposeType,
  loanTermPeriodMonthCount,
  mailAwayIndicator,
  netWireAmount,
  newYorkProcessingFeeAmount,
  notaryExpirationDate,
  notaryUserId,
  operationsDepartment,
  paymentDueDay,
  perDiemPrepaidInterestAmount,
  portfolioLoanIndicator,
  prepaidInterestAmount,
  prepaymentPenaltyExpirationDate,
  promissoryNoteComments,
  propertyInsurancePremiumPaymentType,
  purchaserWarrantyDeedAddedIndicator,
  rateLockEndDate,
  rateLockStartDate,
  requestedLoanAmount,
  scheduledFirstPaymentDate,
  sellerHUDAddedIndicator,
  settlementStatementComments,
  settlementStatementFileNumber,
  shippingCompanyType,
  submissionNotes,
  titleCommitmentAllSubjectPropertiesIncludedIndicator,
  titleCommitmentComments,
  titleCommitmentOwnership,
  titleCommitmentTitleEffectiveDate,
  titleCommitmentTitleExpirationDate,
  titleCommitmentTitlePolicyAmount,
  titleCommitmentTitleVestingType,
  titleCompanyAddressLineText,
  titleCompanyAddressUnitIdentifier,
  titleCompanyCityName,
  titleCompanyClosingAgentContactPointEmail,
  titleCompanyClosingAgentContactPointTelephone,
  titleCompanyClosingAgentFirstName,
  titleCompanyClosingAgentLastName,
  titleCompanyName,
  titleCompanyPostalCode,
  titleCompanyStateCode,
  titleFormsComments,
  titleInsuranceUnderwriter,
  totalAdjustedMonthlyRentAmount,
  totalAsIsAppraisedValueAmount,
  totalAssignmentFeeAmount,
  totalBudgetAmount,
  totalCostAmount,
  totalFloodInsurancePremiumAmount,
  totalFloodInsurancePremiumOutstandingAmount,
  totalHOAFeesAmount,
  totalInitialInsuranceEscrowAmount,
  totalInitialTaxEscrowAmount,
  totalLTARVRatePercent,
  totalLTVRatePercent,
  totalLoanFeesAndClosingCostAmount,
  totalNumberOfProperties,
  totalOutstandingLoanPayoffAmount,
  totalPropertyInsurancePremiumAmount,
  totalPropertyInsurancePremiumOutstandingAmount,
  totalPurchasePriceAmount,
  totalSubjectToAppraisedValueAmount,
  totalTaxAmount,
  totalValuationOrPurchasePriceAmount,
  uccExpirationDate,
  uccFiledIndicator,
  underwriterApprovalComments,
  underwriterApprovalStatus,
  warehouseBankStatusType,
  warehouseBankType,
  wireABARoutingNumber,
  wireAccountName,
  wireAccountNumber,
  wireBankName,
  wireReleaseDate
} from "../inputs/deal.inputs";
import { BaseSchemaDealSections, BaseSchemaSectionsBuilders } from "../types";

const loanInformationSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Loan Information",
      inputs: [
        loanProgramType,
        loanPurposeType,
        loanName,
        portfolioLoanIndicator,
        estimatedClosingDate,
        channel,
        operationsDepartment,
        lenderIdentifier
      ],
      fieldSpecs
    });

const dealTotalsSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Deal Totals",
      inputs: [
        totalPurchasePriceAmount,
        addRuleToInput({
          input: totalBudgetAmount,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
        }),
        totalAsIsAppraisedValueAmount,
        addRuleToInput({
          input: totalSubjectToAppraisedValueAmount,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
        }),
        totalLTVRatePercent,
        addRuleToInput({
          input: totalLTARVRatePercent,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
        }),
        ...addRulesToInputs({
          inputs: [totalTaxAmount, totalHOAFeesAmount],
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
        }),
        totalPropertyInsurancePremiumAmount,
        totalFloodInsurancePremiumAmount,
        addRuleToInput({
          input: totalCostAmount,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
        }),
        requestedLoanAmount,
        totalOutstandingLoanPayoffAmount,
        addRuleToInput({
          input: initialAdvancedFundAmount,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
        }),
        totalLoanFeesAndClosingCostAmount,
        totalValuationOrPurchasePriceAmount,
        addRuleToInput({
          input: lesserOfLotOrPurchase,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
        }),
        totalNumberOfProperties,
        totalAdjustedMonthlyRentAmount,
        cashFromBorrowerAmount,
        cashToBorrowerAmount,
        totalAssignmentFeeAmount
      ],
      fieldSpecs
    });

const loanTermsSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Loan Terms",
      inputs: [
        addRuleToInput({
          input: loanProductType,
          state: r.state,
          rules: [
            {
              field: "isHidden",
              predicate: () => !hideFixNFlipOrEmpty(r.state)
            }
          ]
        }),
        estimatedClosingDate,
        rateLockStartDate,
        rateLockEndDate,
        wireReleaseDate,
        scheduledFirstPaymentDate,
        paymentDueDay,
        lastDayOfClosingMonth,
        loanMaturityDate,
        loanTermPeriodMonthCount,
        ...addRulesToInputs({
          inputs: [
            loanAmortizationPeriodMonthCount,
            loanInterestOnlyPeriodMonthCount,
            interestAccrualMethodType,
            loanPaymentType
          ],
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
        }),
        addRuleToInput({
          input: prepaymentPenaltyExpirationDate,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
        })
      ],
      fieldSpecs
    });

const loanTermsARMSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      isHidden: hideRental30(r.state),
      state: r.state,
      onChange: r.onChange,
      header: "Loan Terms - ARM",
      inputs: [
        firstRateChangeDate,
        indexSourceType,
        capitalStructureType,
        armRepriceRoundingRatePercentType,
        armRepriceRoundingMethodType,
        armRepriceLookbackType,
        armInitialFixedTermMonthCount,
        armFinalAdjustableRateTermMonthCount
      ],
      fieldSpecs
    });

const loanTermsCustomSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Loan Terms - Custom",
      inputs: [uccFiledIndicator, uccExpirationDate],
      fieldSpecs
    });

const titleCompanyClosingAttorneySection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Title Company",
      inputs: [
        titleInsuranceUnderwriter,
        titleCompanyName,
        titleCompanyClosingAgentFirstName,
        titleCompanyClosingAgentLastName,
        titleCompanyClosingAgentContactPointTelephone,
        titleCompanyClosingAgentContactPointEmail,
        titleCompanyAddressLineText,
        titleCompanyAddressUnitIdentifier,
        titleCompanyCityName,
        titleCompanyStateCode,
        titleCompanyPostalCode
      ],
      fieldSpecs
    });

const closingLoanTermsSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Loan Terms",
      inputs: [
        addRuleToInput({
          input: loanProductType,
          state: r.state,
          rules: [
            {
              field: "isHidden",
              predicate: () => !hideFixNFlip(r.state)
            }
          ]
        }),
        estimatedClosingDate,
        rateLockStartDate,
        rateLockEndDate,
        wireReleaseDate,
        scheduledFirstPaymentDate,
        paymentDueDay,
        loanMaturityDate,
        loanTermPeriodMonthCount,
        ...addRulesToInputs({
          inputs: [
            loanAmortizationPeriodMonthCount,
            loanInterestOnlyPeriodMonthCount,
            interestAccrualMethodType,
            loanPaymentType
          ],
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
        }),
        addRuleToInput({
          input: prepaymentPenaltyExpirationDate,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
        }),
        lenderIdentifier
      ],
      fieldSpecs
    });

const closingLoanTermsCustomSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Loan Terms - Custom",
      inputs: [uccFiledIndicator, uccExpirationDate, gracePeriodDays],
      fieldSpecs
    });

const ledgerSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Ledger",
      inputs: [
        totalOutstandingLoanPayoffAmount,
        totalPurchasePriceAmount,
        downPaymentAmount,
        creditReportFeeAmount,
        lenderCounselFeeAmount,
        ...addRulesToInputs({
          inputs: [contractorReviewFeeAmount, feasibilityReviewFeeAmount],
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
        }),
        condoCertificationFeeAmount,
        newYorkProcessingFeeAmount,
        prepaidInterestAmount,
        perDiemPrepaidInterestAmount
      ],
      fieldSpecs
    });

const escrowsImpoundsSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Escrows/Impounds",
      inputs: [
        propertyInsurancePremiumPaymentType,
        totalPropertyInsurancePremiumOutstandingAmount,
        floodInsurancePremiumPaymentType,
        totalFloodInsurancePremiumOutstandingAmount,
        ...addRulesToInputs({
          inputs: [
            totalInitialInsuranceEscrowAmount,
            totalInitialTaxEscrowAmount,
            interestReserveDepositAmount
          ],
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
        }),
        addRuleToInput({
          input: totalBudgetAmount,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideFixNFlip(r.state) }]
        })
      ],
      fieldSpecs
    });

const titleCommitmentSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Title Commitment",
      inputs: [
        titleCommitmentTitleEffectiveDate,
        titleCommitmentTitleExpirationDate,
        titleCommitmentOwnership,
        titleCommitmentTitlePolicyAmount,
        addRuleToInput({
          input: titleCommitmentTitleVestingType,
          state: r.state,
          rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
        }),
        titleCommitmentAllSubjectPropertiesIncludedIndicator,
        titleCommitmentComments
      ],
      fieldSpecs
    });

const settlementStatementSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Settlement Statement",
      inputs: [
        settlementStatementComments,
        cashFromBorrowerAmount,
        cashToBorrowerAmount
      ],
      fieldSpecs
    });

const titleFormsSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Title Forms",
      inputs: [
        eoExpirationDate,
        wireABARoutingNumber,
        wireAccountNumber,
        wireBankName,
        wireAccountName,
        titleFormsComments
      ],
      fieldSpecs
    });

const preFundingSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Pre-Funding",
      inputs: [
        netWireAmount,
        estimatedClosingDate,
        wireReleaseDate,
        warehouseBankType,
        warehouseBankStatusType,
        haircutAmount,
        mailAwayIndicator
      ],
      fieldSpecs
    });

const promissoryNoteWarehouseTrackingSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Promissory Note - Warehouse Tracking",
      inputs: [
        warehouseBankType,
        closingAgentFirstName,
        closingAgentLastName,
        promissoryNoteComments,
        shippingCompanyType,
        settlementStatementFileNumber,
        loanProgramType,
        loanPackageNoteTrackingNumber
      ],
      fieldSpecs
    });

const userAssignmentSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "User Assignment",
      inputs: [notaryExpirationDate, closingAnalystUserId, notaryUserId],
      fieldSpecs
    });

const electronicExecutedLoanPackageSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Electronic Executed Loan Package",
      inputs: [
        loanPackageTrackingNumber,
        loanPackageNoteTrackingNumber,
        loanPackageInternationalExecutionIndicator,
        warehouseBankStatusType,
        achElectedIndicator,
        sellerHUDAddedIndicator,
        purchaserWarrantyDeedAddedIndicator,
        fundingAgentEmailAddress
      ],
      fieldSpecs
    });

const physicalExecutedLoanPackageSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Physical Executed Loan Package",
      inputs: [
        loanPackageTrackingNumber,
        loanPackageNoteReceivedByLenderIndicator
      ],
      fieldSpecs
    });

const underwritingDealApprovalSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Underwriting Deal Approval",
      inputs: [
        underwriterApprovalStatus,
        underwriterApprovalComments,
        submissionNotes
      ],
      fieldSpecs
    });

const exceptionSection =
  (fieldSpecs: EntityFormFieldSpecs<object>) =>
  (r: {
    state: Partial<BaseSchemaDeal>;
    onChange: (v: OnChangeInput) => void;
  }) =>
    buildSection({
      state: r.state,
      onChange: r.onChange,
      header: "Exceptions",
      inputs: [exceptionType, exceptionCommentary, exceptionApprovedBy],
      fieldSpecs
    });

export const dealSectionBuilders: BaseSchemaSectionsBuilders<BaseSchemaDealSections> =
  {
    loanInformationSection,
    dealTotalsSection,
    loanTermsSection,
    loanTermsARMSection,
    loanTermsCustomSection,
    titleCompanyClosingAttorneySection,
    closingLoanTermsSection,
    closingLoanTermsCustomSection,
    ledgerSection,
    escrowsImpoundsSection,
    titleCommitmentSection,
    settlementStatementSection,
    titleFormsSection,
    preFundingSection,
    promissoryNoteWarehouseTrackingSection,
    userAssignmentSection,
    electronicExecutedLoanPackageSection,
    physicalExecutedLoanPackageSection,
    underwritingDealApprovalSection,
    exceptionSection
  };
