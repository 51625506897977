export const basePartyMilestone = {
  None: "None",
  PreSubmission: "Pre-Submission",
  NewBorrower: "New Borrower",
  InitialReview: "Initial Review",
  PendingUnderwriterReview: "Pending Underwriter Review",
  ApprovedPendingRecert: "Approved - Pending Recert",
  Approved: "Approved",
  Denied: "Denied"
} as const;

export const baseLOCStatus = {
  None: "None",
  PreSubmission: "Pre-Submission",
  NewBorrower: "New Borrower",
  InitialReview: "Initial Review",
  PendingUnderwriterReview: "Pending Underwriter Review",
  ApprovedPendingRecert: "Approved - Pending Recert",
  Approved: "Approved",
  Denied: "Denied"
} as const;

export const baseFixNFlipNBridgePlusTier = {
  New: "New",
  ExperiencedThreeToNine: "Experienced 3-9",
  ExperiencedTenPlus: "Experienced 10+"
} as const;
